<template>
    <div class="manage-applicants">
      <h1>Manage Applicants</h1>
      <p>Applicant list and pipeline for a specific job (placeholder).</p>
      <!-- Eventually, we’ll display applicants from Firestore: jobs/{jobId}/applicants -->
      <ul>
        <li>Jane Doe (stage: New)</li>
        <li>John Smith (stage: Interview)</li>
      </ul>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ManageApplicants',
    setup() {
      // Later, we might accept a jobId param: /company/manage-applicants/:jobId
      // Then fetch from Firestore
    },
  };
  </script>
  
  <style scoped>
  .manage-applicants {
    padding: 1rem;
  }
  </style>
  
<template>
    <div class="company-dashboard">
      <h1>Company Dashboard</h1>
      <p>This is your main dashboard for company users.</p>
      <!-- Placeholder content; we will add real data later -->
    </div>
  </template>
  
  <script>
  export default {
    name: 'CompanyDashboard',
    setup() {
      // We'll add logic here later (e.g. fetch jobs, display stats, etc.)
    },
  };
  </script>
  
  <style scoped>
  .company-dashboard {
    padding: 1rem;
  }
  </style>
  
<template>
  <div class="apply-job">
    <!-- Hero Section -->
    <header v-if="job" class="hero">
      <div class="hero-content">
        <div class="hero-preheading">Apply For:</div>
        <h1 class="hero-title">{{ job.title }}</h1>
        <p class="hero-subtitle">
          <span class="hero-label">Company:</span> {{ job.company }}
        </p>
        <p class="hero-location">
          <span class="hero-label">Location:</span> {{ job.city }}, {{ job.state }}
        </p>
      </div>
    </header>

    <!-- Loading State -->
    <div v-if="loading" class="loading-state">
      Loading job details...
    </div>

    <!-- Error State -->
    <div v-else-if="error" class="error-state">
      {{ error }}
    </div>

    <!-- Main Content: Two-Column Layout + Application Form -->
    <div v-else class="apply-job-content">
      <!-- Two-Column Grid for Job Description & Job Details -->
      <div class="two-column-grid">
        <!-- Job Description in Left Column -->
        <section v-if="job" class="card job-description-section">
          <h2 class="section-title description-heading">Job Description</h2>
          <div class="job-description" v-html="formatDescription(job.description)"></div>
        </section>

        <!-- Job Details in Right Column -->
        <section v-if="job" class="card job-details-section">
          <h2 class="section-title">Job Details</h2>

          <!-- Remote vs. On-Site -->
          <p v-if="job.remote" class="detail-line">
            <strong>Location:</strong>
            This is a remote opportunity
            <span v-if="job.remoteStates && job.remoteStates.length">
              (open to candidates in {{ job.remoteStates.join(', ') }})
            </span>.
          </p>
          <p v-else class="detail-line">
            <strong>Location:</strong> {{ job.city }}, {{ job.state }}
          </p>

          <!-- Job Type -->
          <p v-if="job.type" class="detail-line">
            <strong>Type:</strong> {{ job.type }}
          </p>

          <!-- Salary Range -->
          <p v-if="job.salaryRange && job.salaryRange.min && job.salaryRange.max" class="detail-line">
            <strong>Salary Range:</strong>
            ${{ job.salaryRange.min.toLocaleString() }} - ${{ job.salaryRange.max.toLocaleString() }}
          </p>

          <!-- Skills (as "chips") -->
          <div v-if="job.allSkills && job.allSkills.length" class="detail-line">
            <strong>Skills:</strong>
            <ul class="skills-list">
              <li
  v-for="(skill, idx) in job.allSkills.filter(s => s.toLowerCase() !== 'none')"
  :key="idx"
  class="skill-chip"
>
  {{ skill }}
</li>

            </ul>
          </div>
        </section>
      </div>

      <!-- Application Form Section -->
      <section v-if="job" class="card application-section">
        <h2 class="section-title">Submit Your Application</h2>

        <form @submit.prevent="submitApplication" class="application-form">
          <!-- Resume Upload -->
          <div class="form-field resume-upload">
            <label for="resumeInput" class="resume-label">
              Upload Resume<span class="required">*</span>
            </label>
            <div class="file-input-wrapper">
              <div class="custom-file-upload">
                <div class="file-upload-content">
                  <i class="fas fa-cloud-upload-alt upload-icon"></i>
                  <div class="upload-text">
                    <p>Drag and drop your resume here</p>
                    <p class="upload-subtext">or click to browse files</p>
                    <p class="file-types">Supported formats: PDF, DOCX</p>
                  </div>
                </div>
              </div>
              <input
                id="resumeInput"
                type="file"
                class="file-input"
                @change="handleFileUpload"
                accept=".pdf,.docx"
                required
              />
            </div>
            <div v-if="resumeFile" class="file-info">
              <i class="fas fa-file-alt file-icon"></i>
              <span class="selected-filename">{{ resumeFile.name }}</span>
            </div>
            <button
              type="button"
              class="smart-fill-btn"
              @click.prevent="parseResume"
              :disabled="!resumeFile || parsing"
              title="Uses AI to auto-fill the application fields from your resume."
            >
              <i v-if="parsing" class="fas fa-spinner fa-spin"></i>
              <span v-else>
                <i class="fas fa-magic"></i>
                Auto Fill with AI
              </span>
            </button>
            <p class="button-description">
              Let AI help fill in the application fields from your resume.
            </p>
          </div>

          <!-- Common Required Fields -->
          <div class="form-field">
            <label>First Name<span class="required">*</span></label>
            <input v-model="applicant.firstName" required />
          </div>
          <div class="form-field">
            <label>Last Name<span class="required">*</span></label>
            <input v-model="applicant.lastName" required />
          </div>
          <div class="form-field">
            <label>Email<span class="required">*</span></label>
            <input type="email" v-model="applicant.email" required />
          </div>
          <div class="form-field">
            <label>Phone Number<span class="required">*</span></label>
            <input type="tel" v-model="applicant.phone" required />
          </div>

          <!-- DEI & Compliance fields (all required) -->
          <h3 class="section-subtitle">Additional Information <span class="required">*</span></h3>
          <div class="form-field">
            <label>Gender <span class="required">*</span></label>
            <select v-model="applicant.dei.gender" required>
              <option disabled value="">Please select</option>
              <option>Male</option>
              <option>Female</option>
              <option>Non-binary</option>
              <option>I do not wish to answer</option>
            </select>
          </div>
          <div class="form-field">
            <label>Race <span class="required">*</span></label>
            <select v-model="applicant.dei.race" required>
              <option disabled value="">Please select</option>
              <option>White</option>
              <option>Black or African American</option>
              <option>Hispanic or Latino</option>
              <option>Asian</option>
              <option>Native American or Alaska Native</option>
              <option>Two or more races</option>
              <option>I do not wish to answer</option>
            </select>
          </div>
          <div class="form-field">
            <label>Veteran Status <span class="required">*</span></label>
            <select v-model="applicant.dei.veteranStatus" required>
              <option disabled value="">Please select</option>
              <option>Not a Veteran</option>
              <option>Veteran</option>
              <option>I do not wish to answer</option>
            </select>
          </div>
          <div class="form-field">
            <label>Are you disabled? <span class="required">*</span></label>
            <select v-model="applicant.dei.isDisabled" required>
              <option disabled value="">Please select</option>
              <option>No</option>
              <option>Yes</option>
              <option>I do not wish to answer</option>
            </select>
          </div>
          <div class="form-field">
            <label>Requires Sponsorship? <span class="required">*</span></label>
            <select v-model="applicant.dei.requiresSponsorship" required>
              <option disabled value="">Please select</option>
              <option>false</option>
              <option>true</option>
            </select>
          </div>
          <div class="form-field">
            <label>Authorized to Work? <span class="required">*</span></label>
            <select v-model="applicant.dei.isAuthorizedToWork" required>
              <option disabled value="">Please select</option>
              <option>false</option>
              <option>true</option>
            </select>
          </div>
          <div class="form-field">
            <label>At least 18? <span class="required">*</span></label>
            <select v-model="applicant.dei.isAtLeast18" required>
              <option disabled value="">Please select</option>
              <option>false</option>
              <option>true</option>
            </select>
          </div>
          <div class="form-field">
            <label>At least 21? <span class="required">*</span></label>
            <select v-model="applicant.dei.isAtLeast21" required>
              <option disabled value="">Please select</option>
              <option>false</option>
              <option>true</option>
            </select>
          </div>

          <!-- Additional Questions -->
          <div v-for="question in questions" :key="question.id" class="form-field">
            <label :for="question.id">
              {{ question.label }}
              <span v-if="question.required" class="required">*</span>
            </label>
            <template v-if="question.type === 'short-answer'">
              <input
                :id="question.id"
                v-model="applicant.additionalAnswers[question.id]"
                :required="question.required"
              />
            </template>
            <template v-else-if="question.type === 'long-answer'">
              <textarea
                :id="question.id"
                v-model="applicant.additionalAnswers[question.id]"
                :required="question.required"
              ></textarea>
            </template>
            <template v-else-if="question.type === 'select'">
              <div class="select-wrapper">
                <select
                  :id="question.id"
                  v-model="applicant.additionalAnswers[question.id]"
                  :required="question.required"
                >
                  <option value="" disabled selected>Select an option</option>
                  <option
                    v-for="option in question.options"
                    :key="option"
                    :value="option"
                  >
                    {{ option }}
                  </option>
                </select>
              </div>
            </template>
          </div>

          <button type="submit" class="cta-btn apply-btn">Submit Application</button>
        </form>
      </section>
    </div>

    <!-- Pop-up Modal for Resume Parse Success -->
    <transition name="fade">
      <div v-if="showParsePopup" class="modal-overlay" @click="closeParseModal">
        <div class="modal-content" @click.stop="">
          <h2>Resume Parsed</h2>
          <p>We have successfully parsed your resume. Relevant fields have been populated.</p>
          <button @click="closeParseModal">OK</button>
        </div>
      </div>
    </transition>

    <!-- Pop-up Modal for Application Submission Success -->
    <transition name="fade">
      <div
        v-if="showApplicationSuccessPopup"
        class="modal-overlay"
        @click="closeApplicationSuccessModal"
      >
        <div class="modal-content" @click.stop="">
          <h2>Application Submitted</h2>
          <p>Your application has been successfully submitted. Thank you!</p>
          <button @click="closeApplicationSuccessModal">OK</button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup>
/* ---------------------------
   Imports & Reactive Refs
---------------------------- */
import { onMounted, ref, computed } from 'vue'
import { useRoute } from 'vue-router'
import { useHead } from '@vueuse/head'
import { db, storage } from "@/firebase"
import { collection, doc, getDoc, getDocs, setDoc } from "firebase/firestore"
import { ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage"
import { marked } from "marked"
import { v4 as uuidv4 } from "uuid"

/* ---------------------------
   Reactive State
---------------------------- */
const route = useRoute()

const job = ref(null)
const jobDocId = ref(null)
const applicant = ref({
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  dei: {
    gender: "",
    race: "",
    veteranStatus: "",
    isDisabled: "",
    requiresSponsorship: "",
    isAuthorizedToWork: "",
    isAtLeast18: "",
    isAtLeast21: ""
  },
  additionalAnswers: {}
})
const resumeFile = ref(null)
const loading = ref(true)
const error = ref(null)
const questions = ref([])
const parsing = ref(false)
const showParsePopup = ref(false)
const showApplicationSuccessPopup = ref(false)

/* ---------------------------
   Lifecycle
---------------------------- */
onMounted(async () => {
  console.log("ApplyJob component created")
  console.log("Received job docId:", route.params.docId)

  jobDocId.value = route.params.docId
  await initPage()
  injectStructuredData()
})

/* ---------------------------
   Methods & Handlers
---------------------------- */
async function initPage() {
  await fetchJob()
  await fetchQuestions()
}

function validateForm() {
  // Basic required fields
  if (
    !applicant.value.firstName.trim() ||
    !applicant.value.lastName.trim() ||
    !applicant.value.email.trim() ||
    !applicant.value.phone.trim()
  ) {
    alert("Please fill in all required fields.")
    return false
  }

  // DEI required fields
  const { dei } = applicant.value
  if (
    !dei.gender ||
    !dei.race ||
    !dei.veteranStatus ||
    !dei.isDisabled ||
    !dei.requiresSponsorship ||
    !dei.isAuthorizedToWork ||
    !dei.isAtLeast18 ||
    !dei.isAtLeast21
  ) {
    alert("Please fill in all DEI fields (or choose 'I do not wish to answer').")
    return false
  }

  if (!resumeFile.value) {
    alert("Please upload your resume.")
    return false
  }

  return true
}

async function submitApplication() {
  if (!validateForm()) {
    return
  }

  loading.value = true

  try {
    const applicationId = uuidv4()
    console.log("Generated Application ID:", applicationId)

    if (!jobDocId.value) {
      console.error("Firestore document ID for the job is undefined")
      alert("An error occurred. Please refresh the page and try again.")
      loading.value = false
      return
    }

    // 1) Upload Resume to Firebase Storage
    const storagePath = `unauthenticated_resumes/${jobDocId.value}/applicants/${applicationId}/${resumeFile.value.name}`
    const sRef = storageRef(storage, storagePath)
    await uploadBytes(sRef, resumeFile.value)
    console.log(`Resume uploaded to: ${storagePath}`)

    // 2) Get Download URL
    const resumeUrl = await getDownloadURL(sRef)

    // 3) Write to jobs/{jobDocId}/applicants/{applicationId}
    const now = new Date()
    const applicationData = {
      applicationId,
      appliedOn: now,
      firstName: applicant.value.firstName,
      lastName: applicant.value.lastName,
      email: applicant.value.email,
      phone: applicant.value.phone,
      jobId: job.value.id,
      answers: applicant.value.additionalAnswers, // Additional Qs
      resumeUrl,
      dei: { ...applicant.value.dei }
    }

    const applicantsRef = collection(db, "jobs", jobDocId.value, "applicants")
    await setDoc(doc(applicantsRef, applicationId), applicationData)
    console.log("Application data saved to job's subcollection.")

    // 4) Also insert into appQueue
    const queueId = uuidv4()
    const queueRef = collection(db, "appQueue")
    const queueData = {
      assignedTo: null,
      complianceAnswers: {}, // if you store a separate map
      createdAt: now,
      email: applicant.value.email,
      firstName: applicant.value.firstName,
      lastName: applicant.value.lastName,
      phoneNumber: applicant.value.phone,
      resumeUrl,
      status: "pending",
      userId: null, // or your actual user if you track it

      // Flags to indicate it was web-based single apply
      webBasedApp: true,
      multiApplyFeature: false, // single job only

      jobUrl: job.value.url || "",
      jobId: job.value.id,
      location: `${job.value.city || ""},${job.value.state || ""}`,

      // We pass the cpa field from the job doc here as a string:
      cpa: job.value.cpa ? String(job.value.cpa) : "",

      // Copy DEI fields
      gender: applicant.value.dei.gender,
      race: applicant.value.dei.race,
      veteranStatus: applicant.value.dei.veteranStatus,
      isDisabled: applicant.value.dei.isDisabled,
      requiresSponsorship: applicant.value.dei.requiresSponsorship === "true",
      isAuthorizedToWork: applicant.value.dei.isAuthorizedToWork === "true",
      isAtLeast18: applicant.value.dei.isAtLeast18 === "true",
      isAtLeast21: applicant.value.dei.isAtLeast21 === "true"
    }
    await setDoc(doc(queueRef, queueId), queueData)
    console.log("Application data also saved to appQueue.")

    // 5) Show success popup
    showApplicationSuccessPopup.value = true
    resetForm()
  } catch (err) {
    console.error("Error submitting application:", err)
    alert("An error occurred while submitting your application. Please try again.")
  } finally {
    loading.value = false
  }
}

async function fetchJob() {
  try {
    const jobDocRef = doc(db, "jobs", jobDocId.value)
    const jobDocSnap = await getDoc(jobDocRef)

    if (jobDocSnap.exists()) {
      job.value = { id: jobDocId.value, ...jobDocSnap.data() }
      console.log("Fetched job data:", job.value)
    } else {
      error.value = "Job not found."
      console.error("No document found with the doc ID:", jobDocId.value)
    }
  } catch (err) {
    error.value = "Error fetching job details."
    console.error("Error fetching job:", err)
  } finally {
    loading.value = false
  }
}

async function fetchQuestions() {
  if (!jobDocId.value) return

  try {
    const questionsRef = collection(db, "jobs", jobDocId.value, "questions")
    const snapshot = await getDocs(questionsRef)
    questions.value = snapshot.docs.map(d => ({ id: d.id, ...d.data() }))
  } catch (err) {
    console.error("Error fetching questions:", err)
  }
}

function handleFileUpload(event) {
  resumeFile.value = event.target.files[0]
  console.log("Resume file selected:", resumeFile.value)
}

async function parseResume() {
  if (!resumeFile.value) {
    alert("Please upload your resume before parsing.")
    return
  }

  parsing.value = true
  console.log("Initiating parseResume. File name:", resumeFile.value.name)

  try {
    const formData = new FormData()
    formData.append("resume", resumeFile.value)
    formData.append("questions", JSON.stringify(questions.value))

    console.log("Making fetch request to parsing service with form data...")
    const response = await fetch(
      "https://resume-parsing-service-923505374158.us-central1.run.app/parse-resume",
      { method: "POST", body: formData }
    )

    console.log("Response received:", response)

    if (!response.ok) {
      console.error("Response not OK. Status:", response.status, response.statusText)
      const errorText = await response.text()
      console.error("Error response body:", errorText)
      throw new Error("Resume parsing request failed.")
    }

    const data = await response.json()

    if (data.parsedData) {
      const parsed = data.parsedData

      if (parsed.firstName) applicant.value.firstName = parsed.firstName
      if (parsed.lastName) applicant.value.lastName = parsed.lastName
      if (parsed.email) applicant.value.email = parsed.email
      if (parsed.phone) applicant.value.phone = parsed.phone

      if (parsed.answers && typeof parsed.answers === "object") {
        for (const [qid, ans] of Object.entries(parsed.answers)) {
          applicant.value.additionalAnswers[qid] = ans
        }
      }

      showParsePopup.value = true
    } else if (data.error) {
      console.error("Backend returned an error:", data.error)
      alert(`Error parsing resume: ${data.error}`)
    } else {
      console.warn("No parsedData or error field found in response:", data)
      alert("No data returned. Please try another resume.")
    }
  } catch (err) {
    console.error("Error parsing resume:", err)
    alert("An error occurred while parsing the resume. Please try again.")
  } finally {
    parsing.value = false
  }
}

function resetForm() {
  applicant.value = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    dei: {
      gender: "",
      race: "",
      veteranStatus: "",
      isDisabled: "",
      requiresSponsorship: "",
      isAuthorizedToWork: "",
      isAtLeast18: "",
      isAtLeast21: ""
    },
    additionalAnswers: {}
  }
  resumeFile.value = null
}

function formatDescription(description) {
  if (!description) return ""

  const formattedText = description
    .replace(/\\n\\n/g, "\n\n")
    .replace(/\\n/g, "\n")
    .replace(/\*\*/g, "**")

  marked.setOptions({ breaks: true, gfm: true })

  return marked(formattedText)
}

function closeParseModal() {
  showParsePopup.value = false
}

function closeApplicationSuccessModal() {
  showApplicationSuccessPopup.value = false
}

/* ---------------------------
   Structured Data
---------------------------- */
function injectStructuredData() {
  const structuredData = generateStructuredData()

  if (!Object.keys(structuredData).length) {
    console.error("No structured data to inject.")
    return
  }

  const script = document.createElement("script")
  script.type = "application/ld+json"
  script.textContent = JSON.stringify(structuredData)
  document.head.appendChild(script)

  console.log("Structured data injected:", structuredData)
}

function generateStructuredData() {
  if (!job.value) return {}

  // Convert timestamp to ISO 8601 format
  const datePostedISO = job.value.datePosted?.seconds
    ? new Date(job.value.datePosted.seconds * 1000).toISOString()
    : new Date().toISOString()

  const validThroughISO =
    job.value.validThrough ||
    new Date(new Date().setDate(new Date().getDate() + 30)).toISOString()

  const structuredData = {
    "@context": "https://schema.org/",
    "@type": "JobPosting",
    title: job.value.title || "Unknown Job Title",
    description: job.value.description || "No description provided.",
    datePosted: datePostedISO,
    validThrough: validThroughISO,
    employmentType: job.value.employmentType || "FULL_TIME",
    hiringOrganization: {
      "@type": "Organization",
      name: job.value.company || "Unknown Company",
      sameAs: "https://www.fylterjobs.com"
    },
    jobLocation: {
      "@type": "Place",
      address: {
        "@type": "PostalAddress",
        streetAddress: job.value.streetAddress || "N/A",
        addressLocality: job.value.city || "Unknown City",
        addressRegion: job.value.state || "Unknown State",
        postalCode: job.value.zip || "00000",
        addressCountry: { "@type": "Country", name: "US" }
      }
    },
    baseSalary: {
      "@type": "MonetaryAmount",
      currency: "USD",
      value: {
        "@type": "QuantitativeValue",
        value: job.value.salary || 0,
        unitText: "YEAR"
      }
    },
    applyUrl: `${window.location.origin}/apply/${job.value.id}`
  }

  // If remote is true, add jobLocationType
  if (job.value.remote) {
    structuredData.jobLocationType = "TELECOMMUTE"
  }

  return structuredData
}

/* ---------------------------
   useHead for Canonical & Title
---------------------------- */
const canonicalUrl = computed(() =>
  jobDocId.value
    ? `https://www.fylterjobs.com/apply/${jobDocId.value}`
    : "https://www.fylterjobs.com"
)

const pageTitle = computed(() => {
  if (job.value && job.value.title) {
    return `Apply for ${job.value.title}`
  }
  return "Apply for a Job"
})

useHead({
  title: pageTitle,
  link: [
    {
      rel: "canonical",
      href: canonicalUrl.value
    }
  ]
})
</script>

<style scoped>
/* ---------------------------
   Global Container
---------------------------- */
.apply-job {
  text-align: center;
  margin: 0;
  background-color: #f3ede2; /* Light neutral background */
  width: 100%;
}

/* ---------------------------
   Hero Section
---------------------------- */
.hero {
  background-color: #f3ede2;
  text-align: left;
  color: #333;
  padding: 50px 0;
  width: 100%;
}

.hero-content {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 1rem;
}

.hero-preheading {
  font-size: 1.1rem;
  margin-bottom: 5px;
  color: #666;
}
.hero-title {
  font-size: 2rem;
  margin-top: 0;
  margin-bottom: 10px;
  color: #fc7115;
  line-height: 1.2;
  text-decoration: underline;
}
.hero-subtitle {
  margin: 5px 0;
  color: #666;
  font-size: 1.1rem;
}
.hero-location {
  margin-top: 8px;
  color: #666;
  font-size: 1.1rem;
}

/* Avoid styling label spans incorrectly */
.hero-subtitle .hero-label,
.hero-location .hero-label {
  font-weight: bold;
}

/* ---------------------------
   Loading and Error States
---------------------------- */
.loading-state,
.error-state {
  text-align: center;
  font-size: 1.25rem;
  color: #555;
  padding: 20px;
}

/* ---------------------------
   Main Content Container
---------------------------- */
.apply-job-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 1rem;
}

/* Two-Column Grid for Desktop, stack on mobile */
.two-column-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  margin-bottom: 2rem; /* space below the grid before the form */
}

@media (max-width: 768px) {
  .two-column-grid {
    grid-template-columns: 1fr; /* stack columns on mobile */
  }
}

/* ---------------------------
   Card Styling
---------------------------- */
.card {
  background-color: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  text-align: left; /* left-align text inside cards */
}

/* ---------------------------
   Job Description Section
---------------------------- */
.job-description-section {
  display: flex;
  flex-direction: column;
}
.description-heading {
  margin-top: 0;
  color: #fc7115;
}
.job-description {
  color: #555;
  line-height: 1.6;
  margin-top: 1rem;
}

/* ---------------------------
   Job Details Section
---------------------------- */
.job-details-section {
  display: flex;
  flex-direction: column;
}
.detail-line {
  margin-bottom: 0.75rem;
}
.detail-line strong {
  margin-right: 0.25rem;
}

/* Skills as "chips" */
.skills-list {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  list-style: none;
  padding: 0;
  margin: 0.5rem 0 0;
}
.skill-chip {
  background-color: #eee;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-size: 0.9rem;
}

/* ---------------------------
   Application Form Section
---------------------------- */
.application-section {
  background-color: #fff;
  margin-bottom: 2rem;
}
.section-title {
  color: #fc7115;
  font-size: 1.75rem;
  margin-top: 0;
  margin-bottom: 1rem;
}
.section-subtitle {
  font-size: 1.25rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
  color: #333;
}
.application-form {
  max-width: 600px;
  margin: 0 auto;
}
.form-field {
  margin-bottom: 20px;
  text-align: left;
}
.form-field label {
  display: block;
  font-weight: 600;
  margin-bottom: 5px;
}
.form-field input,
.form-field textarea,
.form-field select {
  width: 100%;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* Dropdown */
.select-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
}
select {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  color: #333;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 15px;
  font-size: 1rem;
  cursor: pointer;
  box-sizing: border-box;
}
.select-wrapper::after {
  content: "▼";
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1rem;
  color: #333;
  pointer-events: none;
}

/* Resume Upload */
.resume-upload {
  background-color: #fafafa;
  margin-bottom: 25px;
  border: 1px dashed #ccc;
  border-radius: 8px;
  padding: 1rem;
}
.resume-label {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 12px;
  color: #333;
}
.file-input-wrapper {
  position: relative;
  width: 100%;
  margin-bottom: 15px;
}
.file-input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 2;
}
.custom-file-upload {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: #fff;
  min-height: 100px;
  transition: background-color 0.3s ease;
}
.custom-file-upload:hover {
  background-color: #fff5ef;
}
.file-upload-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}
.upload-icon {
  font-size: 24px;
  color: #fc7115;
  margin-bottom: 8px;
}
.upload-text {
  font-size: 1rem;
  color: #666;
  text-align: center;
}
.file-types {
  font-size: 0.9rem;
  color: #999;
}
.file-info {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.file-icon {
  color: #666;
}
.selected-filename {
  font-size: 0.95rem;
  color: #333;
}

/* Smart Fill Button */
.smart-fill-btn {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 25px;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 10px;
}
.smart-fill-btn:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}
.smart-fill-btn:hover:not(:disabled) {
  background-color: #0056b3;
}
.button-description {
  font-size: 0.9rem;
  color: #666;
  margin-top: 5px;
}

/* Required Mark */
.required {
  color: red;
  margin-left: 4px;
}

/* Apply Button */
.cta-btn.apply-btn {
  background-color: #fc7115;
  color: white;
  padding: 15px 30px;
  border-radius: 25px;
  text-decoration: none;
  font-weight: bold;
  font-size: 1.2rem;
  border: none;
  cursor: pointer;
  margin-top: 20px;
}
.cta-btn.apply-btn:hover {
  background-color: #e35d0a;
}

/* ---------------------------
   Modals
---------------------------- */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}
.modal-content {
  background-color: #fff;
  border-radius: 8px;
  padding: 2rem;
  box-shadow: 0 2px 10px rgba(0,0,0,0.3);
  max-width: 400px;
  width: 80%;
  text-align: center;
}
.modal-content button {
  background-color: #fc7115;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 25px;
  cursor: pointer;
  margin-top: 20px;
}
.modal-content button:hover {
  background-color: #e35d0a;
}

/* Fade Transition */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

/* ---------------------------
   Responsive Tweaks
---------------------------- */
@media screen and (max-width: 768px) {
  .hero-title {
    font-size: 1.4rem;
  }
  .hero-subtitle,
  .hero-location {
    font-size: 1rem;
  }
  .section-title {
    font-size: 1.4rem;
  }
  .apply-btn {
    width: 100%;
    text-align: center;
  }
}
</style>

<template>
  <div class="multi-apply">
    <header class="multi-apply__header">
      <h1>Multi Apply</h1>
      <p v-if="jobs.length > 0">
        You have selected {{ jobs.length }} job(s):
      </p>
      <p v-else>Loading your selected jobs...</p>
    </header>

    <!-- Display the list of selected jobs -->
    <section v-if="jobs.length > 0" class="selected-jobs-section">
      <ul class="selected-jobs-list">
        <li v-for="job in jobs" :key="job.id" class="selected-jobs-item">
          <h2>{{ job.title }}</h2>
          <p>{{ job.company }}</p>
          <p>{{ job.city }}, {{ job.state }}</p>
        </li>
      </ul>
    </section>

    <!-- Loading / Error states -->
    <div v-if="loading" class="loading-state">
      Loading...
    </div>
    <div v-else-if="error" class="error-state">
      {{ error }}
    </div>

    <!-- The application form, shown after jobs are loaded -->
    <section v-if="jobs.length > 0 && !loading && !error" class="application-section">
      <h2>Submit One Application for All Selected Jobs</h2>
      <form @submit.prevent="submitAllApplications" class="application-form">
        <!-- Resume Upload -->
        <div class="form-field resume-upload">
          <label for="resumeInput" class="resume-label">
            Upload Resume <span class="required">*</span>
          </label>
          <div class="file-input-wrapper">
            <div class="custom-file-upload">
              <div class="file-upload-content">
                <i class="fas fa-cloud-upload-alt upload-icon"></i>
                <div class="upload-text">
                  <p>Drag and drop your resume here</p>
                  <p class="upload-subtext">or click to browse files</p>
                  <p class="file-types">Supported formats: PDF, DOCX</p>
                </div>
              </div>
            </div>
            <input
              id="resumeInput"
              type="file"
              class="file-input"
              @change="handleFileUpload"
              accept=".pdf,.docx"
              required
              :disabled="submitting"
            />
          </div>
          <div v-if="resumeFile" class="file-info">
            <i class="fas fa-file-alt file-icon"></i>
            <span class="selected-filename">{{ resumeFile.name }}</span>
          </div>
          <button
            type="button"
            class="smart-fill-btn"
            @click.prevent="parseResume"
            :disabled="!resumeFile || parsing || submitting"
            title="Uses AI to auto-fill the application fields from your resume."
          >
            <i v-if="parsing" class="fas fa-spinner fa-spin"></i>
            <span v-else>
              <i class="fas fa-magic"></i>
              Auto Fill with AI
            </span>
          </button>
          <p class="button-description">
            Let AI help fill in the application fields from your resume.
          </p>
        </div>

        <!-- Common Required Fields -->
        <div class="form-field">
          <label>First Name <span class="required">*</span></label>
          <input v-model="applicant.firstName" required :disabled="submitting" />
        </div>
        <div class="form-field">
          <label>Last Name <span class="required">*</span></label>
          <input v-model="applicant.lastName" required :disabled="submitting" />
        </div>
        <div class="form-field">
          <label>Email <span class="required">*</span></label>
          <input type="email" v-model="applicant.email" required :disabled="submitting" />
        </div>
        <div class="form-field">
          <label>Phone Number <span class="required">*</span></label>
          <input type="tel" v-model="applicant.phone" required :disabled="submitting" />
        </div>

        <!-- DEI & Compliance fields (all required) -->
        <h3>Additional Information <span class="required">*</span></h3>
        <div class="form-field">
          <label>Gender <span class="required">*</span></label>
          <select v-model="applicant.dei.gender" required :disabled="submitting">
            <option disabled value="">Please select</option>
            <option>Male</option>
            <option>Female</option>
            <option>Non-binary</option>
            <option>I do not wish to answer</option>
          </select>
        </div>
        <div class="form-field">
          <label>Race <span class="required">*</span></label>
          <select v-model="applicant.dei.race" required :disabled="submitting">
            <option disabled value="">Please select</option>
            <option>White</option>
            <option>Black or African American</option>
            <option>Hispanic or Latino</option>
            <option>Asian</option>
            <option>Native American or Alaska Native</option>
            <option>Two or more races</option>
            <option>I do not wish to answer</option>
          </select>
        </div>
        <div class="form-field">
          <label>Veteran Status <span class="required">*</span></label>
          <select v-model="applicant.dei.veteranStatus" required :disabled="submitting">
            <option disabled value="">Please select</option>
            <option>Not a Veteran</option>
            <option>Veteran</option>
            <option>I do not wish to answer</option>
          </select>
        </div>
        <div class="form-field">
          <label>Are you disabled? <span class="required">*</span></label>
          <select v-model="applicant.dei.isDisabled" required :disabled="submitting">
            <option disabled value="">Please select</option>
            <option>No</option>
            <option>Yes</option>
            <option>I do not wish to answer</option>
          </select>
        </div>
        <div class="form-field">
          <label>Requires Sponsorship? <span class="required">*</span></label>
          <select v-model="applicant.dei.requiresSponsorship" required :disabled="submitting">
            <option disabled value="">Please select</option>
            <option>false</option>
            <option>true</option>
          </select>
        </div>
        <div class="form-field">
          <label>Authorized to Work? <span class="required">*</span></label>
          <select v-model="applicant.dei.isAuthorizedToWork" required :disabled="submitting">
            <option disabled value="">Please select</option>
            <option>false</option>
            <option>true</option>
          </select>
        </div>
        <div class="form-field">
          <label>At least 18? <span class="required">*</span></label>
          <select v-model="applicant.dei.isAtLeast18" required :disabled="submitting">
            <option disabled value="">Please select</option>
            <option value="false">No</option>
            <option value="true">Yes</option>
          </select>
        </div>
        <div class="form-field">
          <label>At least 21? <span class="required">*</span></label>
          <select v-model="applicant.dei.isAtLeast21" required :disabled="submitting">
            <option disabled value="">Please select</option>
            <option value="false">No</option>
            <option value="true">Yes</option>
          </select>
        </div>

        <button type="submit" class="cta-btn" :disabled="submitting">
          <span v-if="submitting">Submitting...</span>
          <span v-else>Submit to All Jobs</span>
        </button>
      </form>
    </section>

    <!-- Pop-up Modal for Resume Parse Success -->
    <transition name="fade">
      <div v-if="showParsePopup" class="modal-overlay" @click="closeParseModal">
        <div class="modal-content" @click.stop="">
          <h2>Resume Parsed</h2>
          <p>We have successfully parsed your resume. Relevant fields have been populated.</p>
          <button @click="closeParseModal">OK</button>
        </div>
      </div>
    </transition>

    <!-- Pop-up Modal for Application Submission Success -->
    <transition name="fade">
      <div
        v-if="showApplicationSuccessPopup"
        class="modal-overlay"
        @click="closeApplicationSuccessModal"
      >
        <div class="modal-content" @click.stop="">
          <h2>Applications Submitted</h2>
          <p>Your application has been successfully submitted to all selected jobs. Thank you!</p>
          <button @click="closeApplicationSuccessModal">OK</button>
        </div>
      </div>
    </transition>

    <!-- Full-Page Overlay for "Submitting..." -->
    <transition name="fade">
      <div v-if="submitting" class="modal-overlay">
        <div class="modal-content loading-modal-content">
          <h2>Submitting Applications...</h2>
          <div class="spinner"></div>
          <p>Please do not close or navigate away from this page.</p>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { db, storage } from "@/firebase"
import { doc, getDoc, collection, setDoc } from "firebase/firestore"
import { ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage"
import { v4 as uuidv4 } from "uuid"

const route = useRoute()
const selectedJobIds = ref([])
const jobs = ref([])
const applicant = ref({
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  // DEI & compliance fields
  dei: {
    gender: "",
    race: "",
    veteranStatus: "",
    isDisabled: "",
    requiresSponsorship: "",
    isAuthorizedToWork: "",
    isAtLeast18: "",
    isAtLeast21: ""
  }
})
const resumeFile = ref(null)
const loading = ref(true)
const error = ref(null)
const parsing = ref(false)
const showParsePopup = ref(false)
const showApplicationSuccessPopup = ref(false)

// NEW: Track if the application is currently submitting
const submitting = ref(false)

onMounted(async () => {
  const jobsParam = route.query.jobs
  if (!jobsParam) {
    error.value = "No job IDs provided."
    loading.value = false
    return
  }
  selectedJobIds.value = jobsParam.split(',')
  await fetchJobs()
})

async function fetchJobs() {
  loading.value = true
  jobs.value = []

  try {
    for (const jobId of selectedJobIds.value) {
      const jobDocRef = doc(db, "jobs", jobId)
      const snap = await getDoc(jobDocRef)
      if (snap.exists()) {
        jobs.value.push({
          id: jobId,
          ...snap.data()
        })
      }
    }

    if (jobs.value.length === 0) {
      error.value = "No jobs found for the specified IDs."
    }
  } catch (err) {
    console.error("Error fetching jobs:", err)
    error.value = "Error fetching job details."
  } finally {
    loading.value = false
  }
}

function handleFileUpload(event) {
  resumeFile.value = event.target.files[0]
}

async function parseResume() {
  if (!resumeFile.value) {
    alert("Please upload your resume before parsing.")
    return
  }

  parsing.value = true
  try {
    const formData = new FormData()
    formData.append("resume", resumeFile.value)

    const response = await fetch(
      "https://resume-parsing-service-923505374158.us-central1.run.app/parse-resume",
      { method: "POST", body: formData }
    )
    if (!response.ok) {
      const errorText = await response.text()
      throw new Error("Resume parsing request failed: " + errorText)
    }

    const data = await response.json()
    if (data.parsedData) {
      const parsed = data.parsedData
      if (parsed.firstName) applicant.value.firstName = parsed.firstName
      if (parsed.lastName) applicant.value.lastName = parsed.lastName
      if (parsed.email) applicant.value.email = parsed.email
      if (parsed.phone) applicant.value.phone = parsed.phone
      showParsePopup.value = true
    } else {
      alert("No parsed data returned from AI service.")
    }
  } catch (err) {
    console.error("Error parsing resume:", err)
    alert("An error occurred while parsing the resume. Please try again.")
  } finally {
    parsing.value = false
  }
}

async function submitAllApplications() {
  // Basic front-end validation
  if (
    !applicant.value.firstName.trim() ||
    !applicant.value.lastName.trim() ||
    !applicant.value.email.trim() ||
    !applicant.value.phone.trim() ||
    !applicant.value.dei.gender ||
    !applicant.value.dei.race ||
    !applicant.value.dei.veteranStatus ||
    !applicant.value.dei.isDisabled ||
    !applicant.value.dei.requiresSponsorship ||
    !applicant.value.dei.isAuthorizedToWork ||
    !applicant.value.dei.isAtLeast18 ||
    !applicant.value.dei.isAtLeast21
  ) {
    alert("Please fill in all required fields.")
    return
  }

  if (!resumeFile.value) {
    alert("Please upload your resume.")
    return
  }

  // Show submitting overlay & block navigation
  submitting.value = true
  window.addEventListener("beforeunload", beforeUnloadHandler)

  try {
    const now = new Date()

    // For each selected job:
    for (const job of jobs.value) {
      const appId = uuidv4()

      // 1) Upload the file
      const storagePath = `unauthenticated_resumes/${job.id}/applicants/${appId}/${resumeFile.value.name}`
      const sRef = storageRef(storage, storagePath)
      await uploadBytes(sRef, resumeFile.value)
      const resumeUrl = await getDownloadURL(sRef)

      // 2a) Save the application in the job's subcollection
      const applicantsRef = collection(db, "jobs", job.id, "applicants")
      const appData = {
        applicationId: appId,
        appliedOn: now,
        firstName: applicant.value.firstName,
        lastName: applicant.value.lastName,
        email: applicant.value.email,
        phone: applicant.value.phone,
        resumeUrl,
        jobId: job.id,
        dei: { ...applicant.value.dei }
      }
      await setDoc(doc(applicantsRef, appId), appData)

      // 2b) Also store in 'appQueue'
      const queueId = uuidv4()
      const queueRef = collection(db, "appQueue")
      const queueData = {
        assignedTo: null,
        complianceAnswers: {},
        createdAt: now,
        email: applicant.value.email,
        firstName: applicant.value.firstName,
        lastName: applicant.value.lastName,
        phoneNumber: applicant.value.phone,
        resumeUrl,
        status: "pending",
        userId: null, // or your actual user if you track it
        webBasedApp: true,
        multiApplyFeature: true,
        jobUrl: job.url || "",
        jobId: job.id,
        location: `${job.city || ""},${job.state || ""}`,

        // Add the CPA field from the job doc (as a string)
        cpa: job.cpa ? String(job.cpa) : "",

        // DEI fields
        gender: applicant.value.dei.gender,
        race: applicant.value.dei.race,
        veteranStatus: applicant.value.dei.veteranStatus,
        isDisabled: applicant.value.dei.isDisabled,
        requiresSponsorship: applicant.value.dei.requiresSponsorship === "true",
        isAuthorizedToWork: applicant.value.dei.isAuthorizedToWork === "true",
        isAtLeast18: applicant.value.dei.isAtLeast18 === "true",
        isAtLeast21: applicant.value.dei.isAtLeast21 === "true"
      }
      await setDoc(doc(queueRef, queueId), queueData)
    }

    showApplicationSuccessPopup.value = true
    resetForm()
  } catch (err) {
    console.error("Error submitting multi-application:", err)
    alert("An error occurred while submitting your applications. Please try again.")
  } finally {
    submitting.value = false
    window.removeEventListener("beforeunload", beforeUnloadHandler)
  }
}

function resetForm() {
  applicant.value = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    dei: {
      gender: "",
      race: "",
      veteranStatus: "",
      isDisabled: "",
      requiresSponsorship: "",
      isAuthorizedToWork: "",
      isAtLeast18: "",
      isAtLeast21: ""
    }
  }
  resumeFile.value = null
}

function closeParseModal() {
  showParsePopup.value = false
}

function closeApplicationSuccessModal() {
  showApplicationSuccessPopup.value = false
}

// Warn the user if they try to navigate away while submitting
function beforeUnloadHandler(e) {
  e.preventDefault()
  e.returnValue = ""
}
</script>

<style scoped>
.multi-apply {
  background-color: #f3ede2;
  min-height: 100vh;
  padding: 1rem;
}

.multi-apply__header {
  text-align: center;
  margin-bottom: 2rem;
}

.selected-jobs-section {
  background-color: #fff;
  margin: 0 auto 2rem auto;
  max-width: 800px;
  padding: 1rem 2rem;
  border-radius: 8px;
  border: 1px solid #ccc;
}

.selected-jobs-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.selected-jobs-item {
  border-bottom: 1px solid #eee;
  padding: 1rem 0;
}

.loading-state,
.error-state {
  text-align: center;
  margin: 2rem 0;
  font-size: 1.2rem;
}

.application-section {
  background-color: #fbf7f0;
  padding: 2rem;
  max-width: 700px;
  margin: 0 auto 4rem auto;
  border-radius: 8px;
  border: 1px solid #ccc;
}

.application-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.form-field label {
  font-weight: 600;
  margin-bottom: 0.5rem;
  display: block;
}

.form-field input,
.form-field select {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.resume-label {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 12px;
}

.file-input-wrapper {
  position: relative;
  width: 100%;
  margin-bottom: 15px;
}

.custom-file-upload {
  border: 2px dashed #ccc;
  border-radius: 8px;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f9fa;
  transition: all 0.3s ease;
  min-height: 80px;
}

.custom-file-upload:hover {
  border-color: #fc7115;
  background-color: #fff5ef;
}

.file-upload-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.upload-icon {
  font-size: 24px;
  color: #fc7115;
  margin-bottom: 8px;
}

.file-input {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
  top: 0;
  left: 0;
}

.smart-fill-btn {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 25px;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 10px;
}

.smart-fill-btn:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.cta-btn {
  background-color: #fc7115;
  color: #fff;
  padding: 15px 30px;
  border: none;
  border-radius: 25px;
  font-weight: 600;
  font-size: 1.1rem;
  cursor: pointer;
  margin-top: 1rem;
}

.cta-btn:hover {
  background-color: #e35d0a;
}

.required {
  color: red;
  margin-left: 4px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.modal-content {
  background-color: #fff;
  border-radius: 8px;
  padding: 2rem;
  max-width: 400px;
  width: 80%;
  text-align: center;
}

.modal-content button {
  background-color: #fc7115;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 25px;
  cursor: pointer;
  margin-top: 20px;
}

.modal-content button:hover {
  background-color: #e35d0a;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

/* Spinner for the Submitting Overlay */
.loading-modal-content {
  text-align: center;
}

.spinner {
  margin: 20px auto;
  width: 60px;
  height: 60px;
  border: 6px solid #ccc;
  border-top: 6px solid #fc7115;
  border-radius: 50%;
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>

<template>
  <div class="contact-page">
    <!-- Hero Section -->
    <header class="hero">
      <div class="hero-content">
        <h1 class="hero-title">Contact Us</h1>
        <p class="hero-subtitle">We’re here to help you in your journey.</p>
      </div>
    </header>

    <!-- Contact Info / Intro Section -->
    <section class="content-section alt-background">
      <div class="container">
        <h2 class="section-title">We’d Love to Hear from You!</h2>
        <p class="section-text">
          Whether you have questions, feedback, or need assistance, our team is
          here to support you. Complete the form below, and we’ll get back to
          you as soon as possible. Prefer direct outreach? Email us at
          <a href="mailto:support@fylterjobs.com">support@fylterjobs.com</a>.
        </p>
      </div>
    </section>

    <!-- Contact Form Section -->
    <section class="content-section">
      <div class="container">
        <h2 class="section-title">Send Us a Message</h2>
        <form @submit.prevent="sendContactRequest" class="contact-form">
          <div class="form-group">
            <label for="contact-reason">Reason for Contacting</label>
            <select v-model="contactReason" id="contact-reason" required>
              <option disabled value="">Please select your reason</option>
              <option>Job Search Assistance</option>
              <option>Technical Support</option>
              <option>General Inquiry</option>
            </select>
          </div>

          <div class="form-group">
            <label for="email">Your Email</label>
            <input
              type="email"
              id="email"
              v-model="email"
              placeholder="Enter your email"
              required
            />
          </div>

          <div class="form-group">
            <label for="message">Your Message</label>
            <textarea
              id="message"
              v-model="message"
              placeholder="Type your message here..."
              required
            ></textarea>
          </div>

          <button type="submit" class="cta-button">Send Message</button>
        </form>
      </div>
    </section>
  </div>
</template>

<script>
// Import Firestore instance and required Firestore functions
import { db } from '@/firebase'; // Ensure you have Firebase set up in firebase.js
import { collection, addDoc, serverTimestamp } from 'firebase/firestore'; // Import Firestore functions

export default {
  name: 'ContactPage',
  data() {
    return {
      contactReason: '',
      email: '',
      message: '',
    };
  },
  methods: {
    async sendContactRequest() {
      try {
        // Add contact details to Firestore
        await addDoc(collection(db, 'contact_messages'), {
          contactReason: this.contactReason,
          email: this.email,
          message: this.message,
          timestamp: serverTimestamp(), // Add server timestamp
        });
        alert('Your message has been sent! We will get back to you soon.');

        // Reset the form after submission
        this.contactReason = '';
        this.email = '';
        this.message = '';
      } catch (error) {
        console.error('Error submitting contact form:', error);
        alert('An error occurred. Please try again later.');
      }
    },
  },
};
</script>

<style scoped>
/* Shared Page Styling */
.contact-page {
  padding: 0;
  margin: 0;
  background-color: #f3ede2; /* Matches the About page background */
  font-family: 'FixelDisplay', sans-serif;
  color: #333;
}

/* Hero Section */
.hero {
  background-color: #f3ede2;
  padding: 60px 20px;
  min-height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.hero-content {
  max-width: 100%;
  margin: 0 auto;
}

.hero-title {
  font-size: 3rem;
  color: #fc7115;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 1s ease-out forwards;
  animation-delay: 0.2s;
}

.hero-subtitle {
  font-size: 1.2rem;
  margin-top: 10px;
  color: #333;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 1s ease-out forwards;
  animation-delay: 0.4s;
}

/* Content Section */
.content-section {
  padding: 60px 20px;
  animation: fadeInUp 1s ease-out forwards;
  animation-delay: 0.3s;
  text-align: center;
}

.alt-background {
  background-color: #fbf7f0;
}

.container {
  max-width: 1200px;  /* Consistent max-width */
  margin: 0 auto;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 1s ease-out forwards;
}

.section-title {
  font-size: 2.5rem;
  color: #fc7115;
  margin-bottom: 20px;
}

.section-text {
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 40px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

/* Contact Form */
.contact-form {
  max-width: 700px;  /* Keep the form from stretching too wide */
  margin: 0 auto;
  text-align: left;  /* Left-align labels and fields */
}

.form-group {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

.form-group label {
  font-weight: 600;
  margin-bottom: 8px;
}

.form-group input,
.form-group select,
.form-group textarea {
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  font-family: 'FixelDisplay', sans-serif;
  box-sizing: border-box;
  width: 100%;
}

textarea {
  resize: vertical;
  min-height: 120px;
}

/* CTA Button (Matches About page style) */
.cta-button {
  display: inline-block;
  background-color: #fc7115;
  color: #fff;
  padding: 12px 30px;
  border-radius: 8px;
  text-decoration: none;
  font-size: 1.2rem;
  transition: background-color 0.3s ease;
  cursor: pointer;
  border: none;
  margin-top: 20px;
  text-align: center;
}

.cta-button:hover {
  background-color: #e35f00;
}

/* Fade-in animation */
@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive Adjustments */
@media screen and (max-width: 768px) {
  .hero {
    padding: 40px 20px;
    min-height: 40vh;
  }

  .hero-title {
    font-size: 2rem;
  }

  .hero-subtitle {
    font-size: 1rem;
  }

  .section-title {
    font-size: 2rem;
  }

  .section-text {
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 30px;
  }

  .content-section {
    padding: 40px 20px;
  }
}

@media screen and (max-width: 480px) {
  .hero-title {
    font-size: 1.8rem;
  }

  .section-title {
    font-size: 1.8rem;
  }

  .section-text {
    font-size: 0.95rem;
  }

  .cta-button {
    font-size: 1rem;
    padding: 12px 20px;
  }

  textarea {
    min-height: 100px;
  }
}
</style>

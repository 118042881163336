<template>
    <div class="post-job">
      <h1>Post a New Job</h1>
      <p>Here you can create or edit job postings.</p>
      <!-- Placeholder form or content -->
      <form @submit.prevent="handleSubmit">
        <label for="title">Job Title</label>
        <input id="title" v-model="jobTitle" type="text" />
  
        <label for="description">Job Description</label>
        <textarea id="description" v-model="jobDescription"></textarea>
  
        <button type="submit">Submit</button>
      </form>
    </div>
  </template>
  
  <script>
  export default {
    name: 'PostJob',
    data() {
      return {
        jobTitle: '',
        jobDescription: '',
      };
    },
    methods: {
      handleSubmit() {
        // Placeholder: We’ll add Firestore logic later
        console.log('Job posted:', {
          title: this.jobTitle,
          description: this.jobDescription,
        });
        alert('Job submitted (placeholder).');
      },
    },
  };
  </script>
  
  <style scoped>
  .post-job {
    padding: 1rem;
  }
  form {
    display: flex;
    flex-direction: column;
    max-width: 400px;
  }
  label {
    margin: 0.5rem 0 0.25rem;
  }
  </style>
  
<template>
  <div class="about">
    <!-- Hero Section -->
    <header class="hero">
      <div class="hero-content">
        <h1 class="hero-title">Welcome to Fylter</h1>
        <p class="hero-subtitle">
          Search smarter, Apply effortlessly.
        </p>
      </div>
    </header>

    <!-- Story & Mission Section -->
    <section class="content-section">
      <div class="container">
        <h2 class="section-title">Our Story & Mission</h2>
        <p class="section-text">
          We started Fylter with one simple idea—make finding the right
          opportunities effortless and accessible for everyone. From busy
          professionals seeking their next career step to businesses hunting for
          talented individuals, our goal is to eliminate the usual hurdles.
        </p>
        <p class="section-text">
          Inspired by “Chewy-level” customer care and Mark Cuban’s cost-plus
          philosophy, we’re on a mission to provide unparalleled service and
          transparent pricing. We believe that when you only pay for qualified
          results—like strong candidates or relevant job opportunities—everyone
          benefits. It’s why we’re working toward a cost-per-applicant (CPA)
          model that reflects real value and rewards genuine talent.
        </p>
      </div>
    </section>

    <!-- Values & Vision Section -->
    <section class="content-section alt-background">
      <div class="container">
        <h2 class="section-title">Our Values</h2>
        <div class="values-grid">
          <div class="value-item">
            <i class="fas fa-lightbulb"></i>
            <h3>Simplicity</h3>
            <p>
              By keeping our platform intuitive and user-friendly, we save you
              time and energy—whether you’re applying for jobs or sourcing new
              talent.
            </p>
          </div>
          <div class="value-item">
            <i class="fas fa-users"></i>
            <h3>Inclusivity</h3>
            <p>
              We focus on demonstrated skills, not superficial metrics.
              Everyone deserves a fair shot, so our matching emphasizes what you
              can do rather than who you are.
            </p>
          </div>
          <div class="value-item">
            <i class="fas fa-hand-holding-usd"></i>
            <h3>Fair Pricing</h3>
            <p>
              Borrowing from the cost-plus approach, our long-term vision is a
              transparent CPA model that aligns employer costs with real,
              qualified matches.
            </p>
          </div>
          <div class="value-item">
            <i class="fas fa-headset"></i>
            <h3>On-Demand Support</h3>
            <p>
              Just as Chewy users can chat with a vet, we offer on-demand career
              guidance—starting with AI insights and eventually real experts.
              Because we believe in being there when you need us.
            </p>
          </div>
        </div>

        <h2 class="section-title">Our Vision</h2>
        <p class="section-text">
          At Fylter, we see a future where finding the perfect job or the right
          hire is as simple as a single click. Where every resume is valued for
          genuine skills and every application leads to a real conversation.
          That’s why we’re relentless in our pursuit of creating a platform that
          benefits everyone—efficient for companies, empowering for applicants,
          and transparent for all.
        </p>
      </div>
    </section>

    <!-- Features & How We Help Section -->
    <section class="content-section">
      <div class="container">
        <h2 class="section-title">How We Make It Easy</h2>
        <ul class="feature-list">
          <li>
            <i class="fas fa-bolt"></i>
            <div>
              <strong>One-Click Applications:</strong>
              <span>
                Whether you’re using our app or the web, you can apply to
                relevant jobs with just a resume and a few quick questions—no
                endless forms required.
              </span>
            </div>
          </li>
          <li>
            <i class="fas fa-user-cog"></i>
            <div>
              <strong>Tailored or Quick Apply:</strong>
              <span>
                Need a customized approach? Our interface lets you refine your
                application as needed, ensuring every submission truly reflects
                you.
              </span>
            </div>
          </li>
          <li>
            <i class="fas fa-briefcase"></i>
            <div>
              <strong>Skill-Matching Technology:</strong>
              <span>
                Beyond job titles, Fylter’s smart algorithms highlight roles
                based on capabilities—opening doors you might not have known
                existed.
              </span>
            </div>
          </li>
          <li>
            <i class="fas fa-life-ring"></i>
            <div>
              <strong>Always Available Support:</strong>
              <span>
                Have questions about your career path? Our AI-assisted chat is
                here 24/7, with plans to bring real, professional advisors on
                board for free.
              </span>
            </div>
          </li>
        </ul>
      </div>
    </section>

    <!-- Call to Action Section -->
    <section class="content-section alt-background">
      <div class="container">
        <h2 class="section-title">Ready to Discover More?</h2>
        <p class="section-text">
          Whether you’re a job seeker looking for a better way to apply or an
          employer ready to reduce hiring costs, Fylter is here to help. Reach
          out anytime—our dedicated team is always listening.
        </p>
        <p class="section-text">
          <strong>Let’s make job searching simpler and more rewarding for
          everyone.</strong>
        </p>
        <a href="/contact" class="cta-button">Contact Us</a>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'AboutPage',
};
</script>

<style scoped>
/* Common Layout & Fonts */
.about {
  padding: 0;
  margin: 0;
  background-color: #f3ede2; /* Subtle background color */
  font-family: 'FixelDisplay', sans-serif; /* Example brand font */
  text-align: center;
  color: #333;
}

/* Hero Section */
.hero {
  background-color: #f3ede2;
  padding: 70px 20px;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hero-title {
  font-size: 3rem;
  color: #fc7115;
  margin-bottom: 20px;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 1s ease-out forwards;
}

.hero-subtitle {
  font-size: 1.4rem;
  max-width: 600px;
  margin: 0 auto;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 1s ease-out forwards;
  animation-delay: 0.2s;
}

/* Content Section */
.content-section {
  padding: 60px 20px;
  animation: fadeInUp 1s ease-out forwards;
  animation-delay: 0.3s;
}

.alt-background {
  background-color: #fbf7f0;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.section-title {
  font-size: 2.5rem;
  color: #fc7115;
  margin-bottom: 30px;
}

/* Text */
.section-text {
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 30px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

/* Values Grid */
.values-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  margin-bottom: 40px;
}

.value-item {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 2px 6px rgba(0,0,0,0.1);
}

.value-item i {
  font-size: 2rem;
  color: #fc7115;
  margin-bottom: 15px;
}

.value-item h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

/* Feature List */
.feature-list {
  list-style-type: none;
  padding: 0;
  max-width: 900px;
  margin: 0 auto;
}

.feature-list li {
  display: flex;
  align-items: flex-start;
  margin-bottom: 30px;
}

.feature-list i {
  font-size: 2rem;
  color: #fc7115;
  margin-right: 20px;
  min-width: 40px;
  text-align: center;
}

.feature-list strong {
  font-size: 1.2rem;
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.feature-list span {
  font-size: 1.1rem;
  line-height: 1.8;
}

/* CTA Button */
.cta-button {
  display: inline-block;
  background-color: #fc7115;
  color: #fff;
  padding: 15px 30px;
  border-radius: 8px;
  text-decoration: none;
  font-size: 1.2rem;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #e35f00;
}

/* Fade-in animation */
@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Mobile Styles */
@media screen and (max-width: 768px) {
  .hero-title {
    font-size: 2rem;
  }
  .hero-subtitle {
    font-size: 1.1rem;
  }
  .section-title {
    font-size: 2rem;
  }
  .section-text {
    font-size: 1rem;
  }
  .value-item h3 {
    font-size: 1.2rem;
  }
  .feature-list {
    max-width: 100%;
  }
  .cta-button {
    font-size: 1rem;
    padding: 12px 24px;
  }
}

@media screen and (max-width: 480px) {
  .hero-title {
    font-size: 1.8rem;
  }
  .hero-subtitle {
    font-size: 0.95rem;
  }
  .section-title {
    font-size: 1.8rem;
  }
  .value-item h3 {
    font-size: 1.1rem;
  }
  .feature-list li {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .feature-list i {
    margin-bottom: 10px;
    margin-right: 0;
  }
  .feature-list strong {
    margin-bottom: 5px;
  }
  .cta-button {
    padding: 10px 20px;
  }
}
</style>

// src/utils/cityCoordinates.js

let citiesData = new Map();
let citiesByState = new Map();

// Load and parse the city data
async function loadCityData() {
  try {
    // This assumes your file is in public/ folder or otherwise available at /us_cities_with_coordinates.txt
    const response = await fetch('/us_cities_with_coordinates.txt');
    if (!response.ok) {
      throw new Error(`Failed to load city data: ${response.status} ${response.statusText}`);
    }
    
    const text = await response.text();
    let count = 0;
    
    text.split('\n').forEach(line => {
      if (!line.trim()) return; // Skip empty lines
      
      // Remove leading single quote if present, split on commas
      let [city, state, lat, lon] = line
        .replace(/^'/, '')
        .split(',')
        .map(s => s.trim());
      
      // Skip invalid lines
      if (!city || !state || !lat || !lon) {
        console.warn('Skipping invalid line:', line);
        return;
      }
      
      // Parse coordinates
      const latitude = parseFloat(lat);
      const longitude = parseFloat(lon);
      if (isNaN(latitude) || isNaN(longitude)) {
        console.warn('Invalid coordinates for:', city, state);
        return;
      }

      const coordinates = { latitude, longitude };
      const key = `${city},${state}`;

      // Store in main lookup
      citiesData.set(key, coordinates);

      // Store in state-grouped lookup
      if (!citiesByState.has(state)) {
        citiesByState.set(state, new Map());
      }
      citiesByState.get(state).set(city, coordinates);
      
      count++;
    });

    console.log(`Successfully loaded ${count} cities`);
  } catch (error) {
    console.error('Error loading city data:', error);
    throw error;
  }
}

// Initialize data loading
const dataLoadingPromise = loadCityData();

// Get coordinates for a specific city/state
export async function getCoordinates(city, state) {
  await dataLoadingPromise;
  const key = `${city},${state}`;
  return citiesData.get(key) || null;
}

// Get all cities in a state
export async function getCitiesInState(state) {
  await dataLoadingPromise;
  const cityMap = citiesByState.get(state);
  return cityMap ? Array.from(cityMap.keys()).sort() : [];
}

// Get all available states
export async function getAllStates() {
  await dataLoadingPromise;
  return Array.from(citiesByState.keys()).sort();
}

// Check if a city/state combination is valid
export async function isValidLocation(city, state) {
  await dataLoadingPromise;
  const key = `${city},${state}`;
  return citiesData.has(key);
}

// Calculate coordinate ranges for radius search (used if you want a quick bounding box)
export function calculateCoordinateRanges(latitude, longitude, radiusMiles = 50) {
  const latMileRatio = 1 / 69.0; // approx: 1 deg lat ~ 69 miles
  const lonMileRatio = Math.abs((1 / 69.0) * Math.cos(latitude * Math.PI / 180));
  
  return {
    latMin: latitude - (latMileRatio * radiusMiles),
    latMax: latitude + (latMileRatio * radiusMiles),
    lonMin: longitude - (lonMileRatio * radiusMiles),
    lonMax: longitude + (lonMileRatio * radiusMiles)
  };
}

// Calculate distance between two points using Haversine formula
export function calculateDistance(lat1, lon1, lat2, lon2) {
  const R = 3959; // Earth's radius in miles
  const dLat = (lat2 - lat1) * Math.PI / 180;
  const dLon = (lon2 - lon1) * Math.PI / 180;
  
  const a = 
    Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) * 
    Math.sin(dLon/2) * Math.sin(dLon/2);
    
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
  return Math.round(R * c); // Distance in miles
}

// Status or debug
export async function getLocationStatus() {
  await dataLoadingPromise;
  return {
    totalCities: citiesData.size,
    totalStates: citiesByState.size,
    isLoaded: citiesData.size > 0
  };
}

export async function debugGetCityData(state) {
  await dataLoadingPromise;
  if (state) {
    return Array.from(citiesByState.get(state) || []);
  }
  return Array.from(citiesData.entries());
}

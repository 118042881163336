<template>
  <div class="location-filter">
    <div class="grid gap-4">
      <!-- State Selection -->
      <div>
        <label class="block font-medium text-orange-700 mb-1">State</label>
        <select 
          v-model="selectedState" 
          class="input-field w-full"
          :class="{'border-red-500': error && !selectedState}"
          @change="handleStateChange"
        >
          <option value="">Select State</option>
          <option v-for="state in states" :key="state" :value="state">
            {{ state }}
          </option>
        </select>
      </div>

      <!-- City Selection with Type-Ahead -->
      <div>
        <label class="block font-medium text-orange-700 mb-1">City</label>
        <input
          v-model="cityQuery"
          type="text"
          class="input-field w-full"
          :class="{'border-red-500': error && !selectedCity}"
          :disabled="!selectedState || isLoadingCities"
          @input="filterCities"
          placeholder="Type to search for city..."
        />
        <!-- Suggestions Dropdown -->
        <div v-if="filteredCities.length" class="suggestions-dropdown">
          <div
            v-for="city in filteredCities"
            :key="city"
            class="suggestion-item"
            @click="selectCity(city)"
          >
            {{ city }}
          </div>
        </div>
      </div>

      <!-- Search Radius -->
      <div>
        <label class="block font-medium text-orange-700 mb-1">Search Radius</label>
        <select 
          v-model="radius" 
          class="input-field w-full"
          :disabled="!isLocationSelected"
        >
          <option :value="10">10 miles</option>
          <option :value="20">20 miles</option>
          <option :value="30">30 miles</option>
          <option :value="40">40 miles</option>
        </select>
      </div>

      <!-- Remote Options -->
      <div class="space-y-2">
        <div class="flex items-center">
          <input 
            type="checkbox" 
            id="remote" 
            v-model="isRemoteOnly"
            class="h-4 w-4 text-orange-600"
            @change="handleRemoteChange"
          >
          <label for="remote" class="ml-2 text-sm font-medium text-gray-700">
            Remote Preference
          </label>
        </div>
      </div>

      <!-- Error Message -->
      <div v-if="error" class="text-red-500 text-sm">
        {{ error }}
      </div>

      <!-- Location Status -->
      <div v-if="coordinates" class="text-sm text-gray-600">
        Selected: {{ selectedCity }}, {{ selectedState }}
      </div>
    </div>
  </div>
</template>

<script>
import { 
  getAllStates, 
  getCitiesInState, 
  getCoordinates,
  calculateCoordinateRanges,
  isValidLocation
} from '@/utils/cityCoordinates';

export default {
  name: 'LocationFilter',
  
  emits: ['update:location', 'error'],

  data() {
    return {
      states: [],
      cities: [],
      selectedState: '',
      selectedCity: '',
      cityQuery: '',
      filteredCities: [],
      coordinates: null,
      radius: 30,
      error: null,
      isLoadingCities: false,
      isRemoteOnly: false
    };
  },

  computed: {
    isLocationSelected() {
      return this.selectedCity && this.selectedState;
    },

    locationData() {
      return {
        city: this.selectedCity,
        state: this.selectedState,
        coordinates: this.coordinates,
        radius: this.radius,
        searchRange: this.coordinates
          ? calculateCoordinateRanges(this.coordinates.latitude, this.coordinates.longitude, this.radius)
          : null,
        isRemoteOnly: this.isRemoteOnly
      };
    }
  },

  methods: {
    async loadStates() {
      try {
        this.states = await getAllStates();
      } catch (error) {
        this.error = `Error loading states: ${error.message}`;
        this.$emit('error', this.error);
      }
    },

    async handleStateChange() {
      this.selectedCity = '';
      this.cityQuery = '';
      this.coordinates = null;
      this.error = null;

      // Update location immediately if state changed
      this.updateLocation();

      if (!this.selectedState) {
        this.cities = [];
        return;
      }

      this.isLoadingCities = true;
      try {
        this.cities = await getCitiesInState(this.selectedState);
        this.filteredCities = this.cities;
      } catch (error) {
        this.error = `Error loading cities: ${error.message}`;
        this.$emit('error', this.error);
      } finally {
        this.isLoadingCities = false;
      }
    },

    async handleCityChange() {
      this.error = null;
      if (!this.selectedCity || !this.selectedState) {
        this.coordinates = null;
        this.updateLocation();
        return;
      }

      // Validate city/state
      const valid = await isValidLocation(this.selectedCity, this.selectedState);
      if (!valid) {
        this.error = `Invalid city/state: ${this.selectedCity}, ${this.selectedState}`;
        this.coordinates = null;
        this.$emit('error', this.error);
        this.updateLocation();
        return;
      }

      try {
        // If valid, fetch coordinates
        this.coordinates = await getCoordinates(this.selectedCity, this.selectedState);
        if (!this.coordinates) {
          throw new Error('Coordinates not found');
        }
      } catch (err) {
        this.error = `Error getting coordinates: ${err.message}`;
        this.coordinates = null;
        this.$emit('error', this.error);
      }

      this.updateLocation();
    },

    filterCities() {
      if (!this.cityQuery) {
        this.filteredCities = [];
        return;
      }
      this.filteredCities = this.cities.filter(city =>
        city.toLowerCase().includes(this.cityQuery.toLowerCase())
      );
    },

    selectCity(city) {
      this.selectedCity = city;
      this.cityQuery = city;
      this.filteredCities = [];
      this.handleCityChange();
    },

    handleRemoteChange() {
      // If user toggles remote only on,
      // the user might not want city/state
      // but let's preserve them in case they uncheck.
      this.updateLocation();
    },

    updateLocation() {
      this.$emit('update:location', this.locationData);
    },

    // Optional: Method to reset the filter
    reset() {
      this.selectedState = '';
      this.selectedCity = '';
      this.cityQuery = '';
      this.filteredCities = [];
      this.coordinates = null;
      this.error = null;
      this.isRemoteOnly = false;
      this.radius = 30;
      this.updateLocation();
    }
  },

  async created() {
    await this.loadStates();
  }
};
</script>

<style scoped>
.input-field {
  border: 1px solid #e5e7eb;
  border-radius: 0.375rem;
  padding: 0.5rem 0.75rem;
  background-color: white;
  width: 100%;
}

.input-field:disabled {
  background-color: #f3f4f6;
  cursor: not-allowed;
}

.input-field.border-red-500 {
  border-color: #ef4444;
}

.input-field.border-red-500:focus {
  box-shadow: 0 0 0 2px rgba(239, 68, 68, 0.2);
}

.suggestions-dropdown {
  position: absolute;
  background-color: white;
  border: 1px solid #e5e7eb;
  max-height: 150px;
  overflow-y: auto;
  width: 100%;
  z-index: 10;
}

.suggestion-item {
  padding: 0.5rem;
  cursor: pointer;
}

.suggestion-item:hover {
  background-color: #f3f4f6;
}
</style>

<template>
  <div class="jobswipe">
    <!-- App Download Promotion Section -->
    <AppDownload />

    <!-- Search Section -->
    <section class="search-section-container">
      <div class="search-section">
        <form @submit.prevent="executeSearch" class="search-form">
          <h2 class="search-title">Find Your Next Opportunity</h2>
          <!-- Job Title Search -->
          <div class="search-field">
            <label class="search-section-label">Job Title or Keyword</label>
            <div class="search-input-wrapper">
              <input
                v-model="searchQueries.title"
                type="text"
                placeholder="e.g. Sales, Marketing, Engineer..."
                class="search-input"
              />
              <span class="search-icon">
                <SearchIcon />
              </span>
            </div>
          </div>

          <!-- Location Filter -->
          <div class="search-field">
            <label class="search-section-label">Location</label>
            <div class="search-input-wrapper">
              <LocationFilter
                class="search-input"
                @update:location="handleLocationUpdate"
                @error="handleLocationError"
              />
            </div>
          </div>

          <!-- Salary Range -->
          <div class="search-field">
            <label class="search-section-label">Salary Range</label>
            <div class="salary-range">
              <div class="search-field">
                <label class="search-label">Min Salary</label>
                <div class="search-input-wrapper">
                  <select v-model="filters.salaryMin" class="search-select">
                    <option value="30000">$30,000</option>
                    <option value="40000">$40,000</option>
                    <option value="50000">$50,000</option>
                    <option value="60000">$60,000</option>
                    <option value="70000">$70,000</option>
                    <option value="80000">$80,000</option>
                    <option value="100000">$100,000</option>
                    <option value="120000">$120,000</option>
                    <option value="140000">$140,000</option>
                    <option value="175000">$175,000</option>
                    <option value="200000">$200,000</option>
                  </select>
                  <span class="search-icon">
                    <DollarSignIcon />
                  </span>
                </div>
              </div>

              <div class="search-field">
                <label class="search-label">Max Salary</label>
                <div class="search-input-wrapper">
                  <select v-model="filters.salaryMax" class="search-select">
                    <option value="30000">$30,000</option>
                    <option value="40000">$40,000</option>
                    <option value="50000">$50,000</option>
                    <option value="60000">$60,000</option>
                    <option value="70000">$70,000</option>
                    <option value="80000">$80,000</option>
                    <option value="100000">$100,000</option>
                    <option value="120000">$120,000</option>
                    <option value="140000">$140,000</option>
                    <option value="175000">$175,000</option>
                    <option value="200000">$200,000</option>
                    <option value="400000">$200,000+</option>
                  </select>
                  <span class="search-icon">
                    <DollarSignIcon />
                  </span>
                </div>
              </div>
            </div>
          </div>

          <!-- Job Type and Date Posted -->
          <div class="search-grid">
            <div class="search-field">
              <label class="search-section-label">Job Type</label>
              <div class="search-input-wrapper">
                <select v-model="filters.jobType" class="search-select">
                  <option value="">All Types</option>
                  <option value="Full-Time">Full Time</option>
                  <option value="Part-Time">Part Time</option>
                  <option value="Contract">Contract</option>
                  <option value="Internship">Internship</option>
                </select>
                <span class="search-icon">
                  <BriefcaseIcon />
                </span>
              </div>
            </div>

            <div class="search-field">
              <label class="search-section-label">Date Posted</label>
              <div class="search-input-wrapper">
                <select v-model="filters.datePosted" class="search-select">
                  <option value="1">Last 24 Hours</option>
                  <option value="3">Last 3 Days</option>
                  <option value="7">Last 7 Days</option>
                  <option value="14">Last 14 Days</option>
                  <option value="30">Last 30 Days</option>
                </select>
                <span class="search-icon">
                  <CalendarIcon />
                </span>
              </div>
            </div>
          </div>

          <button 
            type="submit"
            class="search-button"
            :disabled="loading"
          >
            {{ loading ? 'Searching...' : 'Search Jobs' }}
          </button>
        </form>
      </div>

      <div v-if="error" class="error-modal">
        <div class="error-modal__content">
          <p>{{ error }}</p>
          <button @click="closeErrorModal" class="error-modal__button">Okay</button>
        </div>
      </div>
    </section>

    <main class="jobswipe__main">
      <!-- Loading placeholder (could be skeleton or overlay) -->
      <div v-if="loading" class="jobs-loading">
        <div v-for="n in 3" :key="n" class="jobs-loading__card">
          <div class="jobs-loading__title"></div>
          <div class="jobs-loading__subtitle"></div>
          <div class="jobs-loading__text-short"></div>
          <div class="jobs-loading__text-long"></div>
        </div>
      </div>

      <div v-else-if="error" class="jobs-error">
        {{ error }}
      </div>

      <!-- If no matched jobs at all -->
      <div v-else-if="displayedJobs.length === 0 && displayedOtherJobs.length === 0">
        <div class="jobs-empty">
          No jobs found. Try adjusting your search criteria.
        </div>
      </div>

      <div v-else>
        <!-- Matched Jobs Section -->
        <div v-if="displayedJobs.length" class="matched-jobs-section">
          <h3 style="margin-bottom:1rem;">Matched Jobs</h3>
            <!-- Add a small summary line -->
  <p class="matched-jobs-summary" style="margin-bottom:1rem;">
    Found {{ jobs.length }} matched jobs 
    for "<strong>{{ searchQueries.title }}</strong>"
    <span v-if="locationFilter.isRemoteOnly">
      in remote listings
    </span>
    <span v-else>
      in {{ locationFilter.city }}, {{ locationFilter.state }}
    </span>
  </p>
          <div class="jobs-grid">
            <div 
              v-for="job in displayedJobs" 
              :key="job.docId" 
              class="job-card"
              :class="{
                'job-card--title': job.relevanceTier === 3,
                'job-card--description': job.relevanceTier === 1
              }"
            >
              <div class="job-card__header">
                <div>
                  <h2 class="job-card__title" v-html="highlightTerm(job.title, searchQueries.title)"></h2>
                  <p class="job-card__company">{{ job.company }}</p>
                </div>
                <div class="job-card__actions">
                  <input
                    type="checkbox"
                    :value="job.docId"
                    v-model="selectedJobs"
                    class="job-select-checkbox"
                    :disabled="selectedJobs.length >= 10 && !selectedJobs.includes(job.docId)"
                  />
                </div>
              </div>

              <div class="job-card__details">
                <p><MapPinIcon class="icon-class" /> {{ job.location }}</p>
                <p>
                  <DollarSignIcon class="icon-class" />
                  ${{ job.salaryRange.min.toLocaleString() }} -
                  ${{ job.salaryRange.max.toLocaleString() }}
                </p>
                <p><BriefcaseIcon class="icon-class" /> {{ job.type }}</p>
                <p v-if="job.remote || job.globallyRemote">
                  <GlobeIcon class="icon-class" />
                  {{ job.globallyRemote ? 'Nationally Remote' : 'Remote' }}
                </p>
              </div>

              <div
                class="job-card__description"
                v-html="truncateDescription(job.description)"
              ></div>

              <div class="job-card__skills">
                <p class="job-card__skills-title">Skills:</p>
                <div class="job-card__skills-list">
                  <span
                    v-for="(skill, index) in job.allSkills
                      .filter(s => s.toLowerCase() !== 'none')
                      .slice(0, 5)"
                    :key="index"
                    class="job-card__skill-tag"
                  >
                    {{ skill }}
                  </span>
                </div>
              </div>

              <button 
                @click="viewJobDetails(job)"
                class="job-card__cta"
              >
                View Details
              </button>
            </div>
          </div>

          <!-- Load More -->
          <div v-if="hasMoreJobs && displayedJobs.length > 0" class="load-more">
            <button
              @click="loadMore"
              class="load-more__button"
              :disabled="loading"
            >
              {{ loading ? 'Loading...' : 'Load More Jobs' }}
            </button>
          </div>
        </div>

        <!-- Separator if both matched and other exist -->
        <div v-if="displayedJobs.length && displayedOtherJobs.length" style="margin:2rem 0;">
          <hr />
        </div>

        <!-- Other Jobs Section -->
        <div v-if="displayedOtherJobs.length">
          <h3>Other Jobs in Your Location</h3>
          <div class="jobs-grid" style="margin-top:1rem;">
            <div
              v-for="job in displayedOtherJobs"
              :key="job.docId"
              class="job-card"
            >
              <div class="job-card__header">
                <div>
                  <h2 class="job-card__title">{{ job.title }}</h2>
                  <p class="job-card__company">{{ job.company }}</p>
                </div>
                <div class="job-card__actions">
                  <input
                    type="checkbox"
                    :value="job.docId"
                    v-model="selectedJobs"
                    class="job-select-checkbox"
                    :disabled="selectedJobs.length >= 10 && !selectedJobs.includes(job.docId)"
                  />
                </div>
              </div>

              <div class="job-card__details">
                <p><MapPinIcon class="icon-class" /> {{ job.location }}</p>
                <p>
                  <DollarSignIcon class="icon-class" />
                  ${{ job.salaryRange.min.toLocaleString() }} -
                  ${{ job.salaryRange.max.toLocaleString() }}
                </p>
                <p><BriefcaseIcon class="icon-class" /> {{ job.type }}</p>
                <p v-if="job.remote || job.globallyRemote">
                  <GlobeIcon class="icon-class" />
                  {{ job.globallyRemote ? 'Nationally Remote' : 'Remote' }}
                </p>
              </div>

              <div
                class="job-card__description"
                v-html="truncateDescription(job.description)"
              ></div>

              <div class="job-card__skills">
                <p class="job-card__skills-title">Skills:</p>
                <div class="job-card__skills-list">
                  <span
                    v-for="(skill, index) in job.allSkills.slice(0,5)"
                    :key="index"
                    class="job-card__skill-tag"
                  >
                    {{ skill }}
                  </span>
                </div>
              </div>

              <button 
                @click="viewJobDetails(job)"
                class="job-card__cta"
              >
                View Details
              </button>
            </div>
          </div>

          <!-- Load More for other jobs -->
          <div v-if="otherJobs.length > displayedOtherJobs.length" class="load-more">
            <button
              @click="loadMoreOtherJobs"
              class="load-more__button"
              :disabled="loading"
            >
              Load More Other Jobs
            </button>
          </div>
        </div>

        <!-- Job Details Modal -->
        <div 
          v-if="selectedJob" 
          class="job-modal-overlay"
          @click="closeJobDetails"
        >
          <div 
            class="job-modal__content"
            @click.stop
          >
            <div class="job-modal__header">
              <div>
                <h2 class="job-modal__title">{{ selectedJob.title }}</h2>
                <p class="job-modal__company">{{ selectedJob.company }}</p>
              </div>
              <button 
                @click="closeJobDetails"
                class="job-modal__close"
              >
                <XIcon />
              </button>
            </div>

            <div class="job-modal__details">
              <div class="job-modal__info">
                <p><MapPinIcon class="icon-class" /> {{ selectedJob.location }}</p>
                <p>
                  <DollarSignIcon class="icon-class" />
                  ${{ selectedJob.salaryRange.min.toLocaleString() }} -
                  ${{ selectedJob.salaryRange.max.toLocaleString() }}
                </p>
                <p><BriefcaseIcon class="icon-class" /> {{ selectedJob.type }}</p>
                <p v-if="selectedJob.remote || selectedJob.globallyRemote">
                  <GlobeIcon class="icon-class" />
                  {{ selectedJob.globallyRemote ? 'Nationally Remote' : 'Remote' }}
                </p>
                <p><CalendarIcon class="icon-class" /> Posted {{ formatDate(selectedJob.datePosted) }}</p>
              </div>

              <div class="job-modal__section">
                <h3 class="job-modal__subtitle">Description</h3>
                <div
                  class="job-modal__description"
                  v-html="highlightTerm(formatDescription(selectedJob.description), searchQueries.title)"
                ></div>
              </div>

              <div class="job-modal__section">
                <h3 class="job-modal__subtitle">Preferred Skills</h3>
                <div class="job-modal__skills">
                  <span
                    v-for="(skill, index) in selectedJob.requiredSkills"
                    :key="index"
                    class="job-modal__skill-tag"
                  >
                    {{ skill.skill }}
                  </span>
                </div>
              </div>

              <div v-if="selectedJob.desiredSkills?.length" class="job-modal__section">
                <h3 class="job-modal__subtitle">Desired Skills</h3>
                <div class="job-modal__skills">
                  <span
                    v-for="(skill, index) in selectedJob.desiredSkills"
                    :key="`desired-${index}`"
                    class="job-modal__skill-tag"
                  >
                    {{ skill.skill }}
                  </span>
                </div>
              </div>

              <div class="job-modal__actions job-modal__actions--split">
                <div class="job-modal__actions-left">
                  <button
                    v-if="selectedJob.url"
                    class="job-modal__company-site"
                    @click="applyOnCompanySite(selectedJob.url)"
                  >
                    Apply on Company Site
                  </button>
                </div>
                <div class="job-modal__actions-right">
                  <button 
                    class="job-modal__apply"
                    @click="applyToJob(selectedJob.docId)"
                  >
                    Apply via Fylter
                  </button>
                  <button 
                    class="job-modal__share"
                    @click="shareJob(selectedJob)"
                  >
                    <ShareIcon />
                    Share
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>

    <!-- Multi-Apply Footer -->
    <div v-if="selectedJobs.length > 0" class="multi-apply-footer">
      <p>Select more jobs to multi-apply (max 10)</p>
      <div>
        <button @click="applyToAllSelectedJobs">
          Apply to All
        </button>
        <button @click="clearSelections">
          Clear Selections
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { db } from "@/firebase";
import { 
  collection, query, where, getDocs, limit, 
  startAfter, orderBy 
} from "firebase/firestore";
import LocationFilter from '@/components/LocationFilter.vue';
import { marked } from 'marked';
import { 
  SearchIcon, 
  MapPinIcon, 
  BriefcaseIcon, 
  DollarSignIcon, 
  CalendarIcon, 
  XIcon, 
  GlobeIcon, 
  ShareIcon
} from 'lucide-vue-next';
import AppDownload from '@/components/AppDownload.vue';

export default {
  name: 'HomePage',
  components: {
    LocationFilter,
    AppDownload,
    SearchIcon,
    MapPinIcon,
    BriefcaseIcon,
    DollarSignIcon,
    CalendarIcon,
    XIcon,
    GlobeIcon,
    ShareIcon
  },

  data() {
    return {
      searchQueries: {
        title: ""
      },
      filters: {
        jobType: "",
        salaryMin: "50000",
        salaryMax: "100000",
        datePosted: "30"
      },
      locationFilter: {
        city: "",
        state: "",
        coordinates: null,
        radius: 30,
        searchRange: null,
        isRemoteOnly: false
      },

      jobs: [],
      otherJobs: [],
      displayedJobs: [],
      displayedOtherJobs: [],

      loading: false,
      error: null,
      // Now 50 instead of 20
      pageSize: 50,
      selectedJob: null,
      showCopyPopup: false,

      // We can tweak these to fetch more or fewer from Firestore in a single pass
      batchLimit: 50,

      // "last doc" references to paginate
      keywordLastVisible: null,
      descriptionLastVisible: null,

      moreKeywordAvailable: true,
      moreDescriptionAvailable: true,

      // Track which jobs are selected (multi-apply)
      selectedJobs: []
    };
  },

  computed: {
    isLocationValid() {
      // If remote-only is chosen or we have city/state + coords
      if (this.locationFilter.isRemoteOnly) return true;
      return (
        this.locationFilter.city &&
        this.locationFilter.state &&
        this.locationFilter.coordinates
      );
    },
    // If either our keyword or description queries are still open, we can load more
    hasMoreJobs() {
      return this.moreKeywordAvailable || this.moreDescriptionAvailable;
    }
  },

  methods: {
    closeErrorModal() {
      this.error = null;
    },

    handleLocationUpdate(locationData) {
      this.locationFilter = locationData;
    },

    handleLocationError(error) {
      this.error = error;
    },

    viewJobDetails(job) {
      this.selectedJob = job;
    },

    closeJobDetails() {
      this.selectedJob = null;
    },

    applyOnCompanySite(url) {
      if (url) {
        window.open(url, '_blank');
      }
    },

    applyToJob(docId) {
      if (docId) {
        const url = this.$router.resolve({ name: 'JobApply', params: { docId } }).href;
        window.open(url, '_blank');
      } else {
        console.error("Job document ID is not available.");
      }
    },

    shareJob(job) {
      const jobUrl = `${window.location.origin}/apply/${job.docId}`;
      navigator.clipboard.writeText(jobUrl)
        .then(() => {
          this.showCopyPopup = true;
          setTimeout(() => {
            this.showCopyPopup = false;
          }, 2000);
        })
        .catch((error) => {
          console.error("Failed to copy job URL:", error);
        });
    },

    truncateDescription(rawDescription, limit = 200) {
      if (!rawDescription) return '';
      const htmlText = marked(
        rawDescription
          .replace(/\\n\\n/g, '\n\n')
          .replace(/\\n/g, '\n')
          .replace(/\*\*/g, '**')
      );
      const plainText = htmlText.replace(/<[^>]*>/g, '');
      let truncated = plainText;
      if (truncated.length > limit) {
        truncated = truncated.slice(0, limit) + '...';
      }
      return this.highlightTerm(truncated, this.searchQueries.title);
    },

    formatDescription(description) {
      if (!description) return '';
      const formattedText = description
        .replace(/\\n\\n/g, '\n\n')
        .replace(/\\n/g, '\n')
        .replace(/\*\*/g, '**');
      marked.setOptions({
        breaks: true,
        gfm: true
      });
      return marked(formattedText);
    },

    highlightTerm(text, term) {
      if (!term || !text) return text;
      const escaped = term.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      const regex = new RegExp(`(${escaped})`, 'gi');
      return text.replace(regex, '<mark>$1</mark>');
    },

    /**
     * Compute match score by counting how many user searchWords
     * appear in this job's titleKeywords array. More words matched => higher score.
     */
    computeMatchScore(job, searchWords) {
      let score = 0;
      if (Array.isArray(job.titleKeywords)) {
        searchWords.forEach(word => {
          if (job.titleKeywords.includes(word)) {
            score += 1;
          }
        });
      }
      return score;
    },

    // --------------------------------------------------------------------------
    // 1) Single pass: check title -> description -> fallback
    // --------------------------------------------------------------------------
    async fetchSingleQueryPass() {
      const rawSearchTerm = this.searchQueries.title.trim().toLowerCase();
      const searchWords = rawSearchTerm.split(/\s+/).filter(Boolean);

      let newJobs = [];

      // Title
      if (searchWords.length && this.moreKeywordAvailable) {
        const keywordSnap = await this.runTitleQuery(searchWords, this.keywordLastVisible);
        if (keywordSnap.docs.length < this.batchLimit) {
          this.moreKeywordAvailable = false;
        } else {
          this.keywordLastVisible = keywordSnap.docs[keywordSnap.docs.length - 1];
        }
        const kwJobs = keywordSnap.docs.map(doc => ({
          docId: doc.id,
          ...doc.data(),
          latitude: doc.data().coordinates?.latitude ?? null,
          longitude: doc.data().coordinates?.longitude ?? null,
          relevanceTier: 3 // title match
        }));
        newJobs.push(...kwJobs);
      }

      // Description
      if (searchWords.length && this.moreDescriptionAvailable && newJobs.length < this.batchLimit) {
        const descSnap = await this.runDescriptionQuery(searchWords, this.descriptionLastVisible);
        if (descSnap.docs.length < this.batchLimit) {
          this.moreDescriptionAvailable = false;
        } else {
          this.descriptionLastVisible = descSnap.docs[descSnap.docs.length - 1];
        }
        const descJobs = descSnap.docs.map(doc => ({
          docId: doc.id,
          ...doc.data(),
          latitude: doc.data().coordinates?.latitude ?? null,
          longitude: doc.data().coordinates?.longitude ?? null,
          relevanceTier: 1 // description/skills match
        }));
        newJobs.push(...descJobs);
      }

      // Fallback: only if user typed NO keyword at all
      if (!searchWords.length && this.moreDescriptionAvailable) {
        const fallbackSnap = await this.runFallbackQuery(this.descriptionLastVisible);
        if (fallbackSnap.docs.length < this.batchLimit) {
          this.moreDescriptionAvailable = false;
        } else {
          this.descriptionLastVisible = fallbackSnap.docs[fallbackSnap.docs.length - 1];
        }
        const fallbackJobs = fallbackSnap.docs.map(doc => ({
          docId: doc.id,
          ...doc.data(),
          latitude: doc.data().coordinates?.latitude ?? null,
          longitude: doc.data().coordinates?.longitude ?? null,
          relevanceTier: 1
        }));
        newJobs.push(...fallbackJobs);
      }

      // Client-side filter (location, salary, etc.)
      newJobs = this.clientSideFilter(newJobs);

      return newJobs;
    },

    // --------------------------------------------------------------------------
    // 2) Accumulate up to 50 new unique jobs by calling fetchSingleQueryPass()
    // --------------------------------------------------------------------------
    async fetchJobsBatch(desiredCount = 50) {
      let totalNew = [];

      while (
        totalNew.length < desiredCount &&
        (this.moreKeywordAvailable || this.moreDescriptionAvailable)
      ) {
        const batch = await this.fetchSingleQueryPass();
        if (!batch.length) {
          // Nothing new at all from this pass => break
          break;
        }

        // Filter out duplicates vs what we already have in `this.jobs`
        // or newly accumulating in totalNew
        const filtered = batch.filter(b =>
          !this.jobs.some(j => j.docId === b.docId) &&
          !totalNew.some(j => j.docId === b.docId)
        );
        if (!filtered.length) {
          // All were duplicates => no point continuing
          break;
        }
        totalNew.push(...filtered);
      }

      // Merge these into the main jobs array
      const combined = [...this.jobs, ...totalNew];
      const uniqueMap = new Map();
      combined.forEach(job => uniqueMap.set(job.docId, job));
      let deduped = Array.from(uniqueMap.values());

      // Compute matchScore
      const rawSearchTerm = this.searchQueries.title.trim().toLowerCase();
      const searchWords = rawSearchTerm.split(/\s+/).filter(Boolean);

      deduped.forEach(job => {
        job.matchScore = this.computeMatchScore(job, searchWords);
      });

      // Sort: first by matchScore desc, then by datePosted desc
      deduped.sort((a, b) => {
        if (b.matchScore !== a.matchScore) return b.matchScore - a.matchScore;
        return b.datePosted.toDate() - a.datePosted.toDate();
      });

      // De-duplicate by Title + Company
      const finalList = [];
      const seenCombos = new Set();
      for (const job of deduped) {
        const comboKey = (job.title + '__' + job.company).toLowerCase();
        if (!seenCombos.has(comboKey)) {
          finalList.push(job);
          seenCombos.add(comboKey);
        }
      }

      this.jobs = finalList;

      // Return how many new jobs we actually got this time
      return totalNew.length;
    },

    // For "Other Jobs" in location
    async fetchOtherJobsInLocation() {
      const constraints = this.buildBaseConstraints();
      const q = query(
        collection(db, "jobs"),
        ...constraints,
        orderBy("datePosted", "desc"),
        limit(20)
      );
      let snap;
      try {
        snap = await getDocs(q);
      } catch (err) {
        console.error("Error in fetchOtherJobsInLocation:", err);
        return [];
      }
      let allLocJobs = snap.docs.map(doc => ({
        docId: doc.id,
        ...doc.data(),
        latitude: doc.data().coordinates?.latitude ?? null,
        longitude: doc.data().coordinates?.longitude ?? null
      }));

      // client-side filtering
      allLocJobs = this.clientSideFilter(allLocJobs);

      // Exclude matched IDs
      const matchedIds = new Set(this.jobs.map(j => j.docId));
      const noMatchJobs = allLocJobs.filter(job => !matchedIds.has(job.docId));

      // sort desc
      noMatchJobs.sort((a, b) => b.datePosted.toDate() - a.datePosted.toDate());

      // De-dup by title+company
      const dedupedOtherJobs = [];
      const seenCombos = new Set();
      for (const job of noMatchJobs) {
        const comboKey = (job.title + '__' + job.company).toLowerCase();
        if (!seenCombos.has(comboKey)) {
          dedupedOtherJobs.push(job);
          seenCombos.add(comboKey);
        }
      }
      return dedupedOtherJobs;
    },

    // --------------------------------------------------------------------------
    // Query builders
    // --------------------------------------------------------------------------
    async runTitleQuery(searchWords, startDoc) {
      try {
        const constraints = this.buildBaseConstraints();
        constraints.push(where("titleKeywords", "array-contains-any", searchWords));
        const q = this.buildQuery(constraints, startDoc);
        return getDocs(q);
      } catch (err) {
        console.error("Error in runTitleQuery:", err);
        return { docs: [] };
      }
    },

    async runDescriptionQuery(searchWords, startDoc) {
      try {
        const constraints = this.buildBaseConstraints();
        constraints.push(where("allSkills", "array-contains-any", searchWords));
        const q = this.buildQuery(constraints, startDoc);
        return getDocs(q);
      } catch (err) {
        console.error("Error in runDescriptionQuery:", err);
        return { docs: [] };
      }
    },

    // Fallback = no keyword only
    async runFallbackQuery(startDoc) {
      try {
        const constraints = this.buildBaseConstraints();
        const q = this.buildQuery(constraints, startDoc);
        return getDocs(q);
      } catch (err) {
        console.error("Error in runFallbackQuery:", err);
        return { docs: [] };
      }
    },

    buildBaseConstraints() {
      const constraints = [];
      constraints.push(where("isActive", "==", true));

      // Salary
      if (this.filters.salaryMin) {
        constraints.push(where("salaryRange.max", ">=", parseInt(this.filters.salaryMin)));
      }
      if (this.filters.salaryMax) {
        constraints.push(where("salaryRange.min", "<=", parseInt(this.filters.salaryMax)));
      }

      // Date posted
      const days = parseInt(this.filters.datePosted);
      const cutoff = new Date();
      cutoff.setDate(cutoff.getDate() - days);
      constraints.push(where("datePosted", ">=", cutoff));

      // Job type
      if (this.filters.jobType) {
        constraints.push(where("type", "==", this.filters.jobType));
      }

      // If not remote only, do bounding box
      if (
        !this.locationFilter.isRemoteOnly &&
        this.locationFilter.coordinates &&
        this.locationFilter.coordinates.latitude != null &&
        this.locationFilter.coordinates.longitude != null &&
        this.locationFilter.radius
      ) {
        const { latitude, longitude } = this.locationFilter.coordinates;
        const R = 3959; // miles
        const deltaLat = (this.locationFilter.radius / R) * (180 / Math.PI);
        const deltaLon =
          (this.locationFilter.radius / (R * Math.cos((latitude * Math.PI) / 180))) *
          (180 / Math.PI);

        const minLat = latitude - deltaLat;
        const maxLat = latitude + deltaLat;
        const minLon = longitude - deltaLon;
        const maxLon = longitude + deltaLon;

        constraints.push(where("coordinates.latitude", ">=", minLat));
        constraints.push(where("coordinates.latitude", "<=", maxLat));
        constraints.push(where("coordinates.longitude", ">=", minLon));
        constraints.push(where("coordinates.longitude", "<=", maxLon));
      }

      return constraints;
    },

    buildQuery(constraints, startDoc, orderField = "datePosted", orderDirection = "desc") {
      let baseQuery = query(
        collection(db, "jobs"),
        ...constraints,
        orderBy(orderField, orderDirection),
        limit(this.batchLimit)
      );
      if (startDoc) {
        baseQuery = query(
          collection(db, "jobs"),
          ...constraints,
          orderBy(orderField, orderDirection),
          startAfter(startDoc),
          limit(this.batchLimit)
        );
      }
      return baseQuery;
    },

    // client-side filter
    clientSideFilter(jobList) {
      const days = parseInt(this.filters.datePosted);
      const cutoff = new Date();
      cutoff.setDate(cutoff.getDate() - days);

      return jobList.filter(job => {
        // posted date
        const isWithinDate = !job.datePosted || job.datePosted.toDate() >= cutoff;
        // salary
        const minOK = parseInt(this.filters.salaryMin) || 0;
        const maxOK = parseInt(this.filters.salaryMax) || 9999999;
        const meetsSalary = (job.salaryRange.max >= minOK) && (job.salaryRange.min <= maxOK);

        // location filter
        let meetsLocation = true;
        if (this.locationFilter.isRemoteOnly) {
          meetsLocation = job.remote || job.globallyRemote;
        } else {
          if (job.remote || job.globallyRemote) {
            // If job is remote but user is searching a city, check same state or distance
            if (job.state === this.locationFilter.state) {
              meetsLocation = true;
            } else if (job.latitude != null && job.longitude != null && this.locationFilter.coordinates) {
              const distance = this.haversineDistance(
                this.locationFilter.coordinates.latitude,
                this.locationFilter.coordinates.longitude,
                job.latitude,
                job.longitude
              );
              meetsLocation = distance <= this.locationFilter.radius;
            } else {
              meetsLocation = (job.state === this.locationFilter.state);
            }
          } else {
            // Non-remote job => strict distance check
            if (job.latitude != null && job.longitude != null && this.locationFilter.coordinates) {
              const distance = this.haversineDistance(
                this.locationFilter.coordinates.latitude,
                this.locationFilter.coordinates.longitude,
                job.latitude,
                job.longitude
              );
              meetsLocation = distance <= this.locationFilter.radius;
            } else {
              meetsLocation = (job.state === this.locationFilter.state);
            }
          }
        }

        return isWithinDate && meetsSalary && meetsLocation;
      });
    },

    haversineDistance(lat1, lon1, lat2, lon2) {
      const R = 3959; // miles
      const dLat = (lat2 - lat1) * (Math.PI / 180);
      const dLon = (lon2 - lon1) * (Math.PI / 180);
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(lat1 * (Math.PI / 180)) *
        Math.cos(lat2 * (Math.PI / 180)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      return R * c;
    },

    formatDate(timestamp) {
      if (!timestamp) return '';
      const date = timestamp.toDate();
      const now = new Date();
      const diffTime = Math.abs(now - date);
      const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
      if (diffDays === 0) return 'Today';
      if (diffDays === 1) return 'Yesterday';
      if (diffDays < 7) return `${diffDays} days ago`;
      return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      });
    },

    // Multi-apply
    applyToAllSelectedJobs() {
      this.$router.push({
        name: 'MultiApply',
        query: { jobs: this.selectedJobs.join(',') }
      });
    },

    clearSelections() {
      this.selectedJobs = [];
    },

    // --------------------------------------------------------------------------
    // Updated search & loadMore
    // --------------------------------------------------------------------------
    async executeSearch() {
      if (!this.isLocationValid) {
        this.error = "Please select both a city and a state (or check 'Remote Preference') to search.";
        return;
      }
      this.error = null;
      this.loading = true;

      // Reset
      this.jobs = [];
      this.otherJobs = [];
      this.displayedJobs = [];
      this.displayedOtherJobs = [];
      this.keywordLastVisible = null;
      this.descriptionLastVisible = null;
      this.moreKeywordAvailable = true;
      this.moreDescriptionAvailable = true;

      try {
        // Attempt to fetch up to 50 matched jobs
        await this.fetchJobsBatch(50);
        // Show the first 50
        this.displayedJobs = this.jobs.slice(0, this.pageSize);

        // "Other Jobs" only if user typed a title
        if (this.searchQueries.title.trim()) {
          const locJobs = await this.fetchOtherJobsInLocation();
          this.otherJobs = locJobs;
          this.displayedOtherJobs = this.otherJobs.slice(0, 5);
        }
      } catch (err) {
        console.error("Error fetching jobs:", err);
        this.error = "Error fetching jobs: " + err.message;
      } finally {
        this.loading = false;
      }
    },

    async loadMore() {
      if (!this.hasMoreJobs) return;
      this.loading = true;

      try {
        // Attempt to get 50 *new* unique jobs
        const addedCount = await this.fetchJobsBatch(50);

        // We'll display that many more (up to 50) in displayedJobs
        // If fewer than 50 were found, we just show what we have
        const nextIndex = this.displayedJobs.length + addedCount;
        this.displayedJobs = this.jobs.slice(0, nextIndex);
      } catch (err) {
        console.error("Error loading more jobs:", err);
        this.error = "Error loading more jobs: " + err.message;
      } finally {
        this.loading = false;
      }
    },

    loadMoreOtherJobs() {
      // 5 at a time for "other jobs"
      const pageSize = 5;
      const nextIndex = this.displayedOtherJobs.length + pageSize;
      this.displayedOtherJobs = this.otherJobs.slice(0, nextIndex);
    }
  }
};
</script>

<style>
/* All your existing styles remain here unchanged */

* {
  box-sizing: border-box;
}

body, .jobswipe {
  font-family: 'FixelDisplay', sans-serif;
  background-color: #f3ede2;
  margin: 0;
  padding: 0;
}

/* Search Section Container */
.search-section-container {
  max-width: 72rem;
  margin: 0 auto;
  padding: 1rem;
  width: 100%;
}

.search-section {
  background-color: #ffffff;
  border: 1px solid #d1d5db;
  border-radius: 0.5rem;
  padding: 2rem;
  margin-bottom: 1.5rem;
  box-shadow: 0 1px 2px rgb(0 0 0 / 0.05);
}

.search-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.search-title {
  font-size: 2rem;
  font-weight: 700;
  color: #fc7115;
  margin-bottom: 2rem;
}

.search-field {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.search-section-label {
  font-size: 1.125rem;
  font-weight: 700;
  color: #333;
  margin-top: 1rem;
}

.search-label {
  font-size: 0.875rem;
  font-weight: 500;
  color: #666;
}

.search-input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.search-input,
.search-select {
  width: 100%;
  padding: 0.875rem 1rem;
  padding-left: 2.5rem;
  border: 1px solid rgb(209, 213, 219);
  border-radius: 0.5rem;
  background-color: white;
  outline: none;
  font-size: 1rem;
}

.search-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%239ca3af' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 1em;
  padding-right: 2.5rem;
}

.search-icon {
  position: absolute;
  left: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  color: rgb(156, 163, 175);
  width: 1.25rem;
  height: 1.25rem;
  pointer-events: none;
  z-index: 1;
}

.search-input:focus,
.search-select:focus {
  border-color: rgb(59, 130, 246);
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.2);
}

.search-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
}
@media (min-width: 640px) {
  .search-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

.salary-range {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
}
@media (min-width: 640px) {
  .salary-range {
    grid-template-columns: repeat(2, 1fr);
  }
}

.search-button {
  padding: 0.75rem 1.5rem;
  background-color: #fc7115;
  color: white;
  font-weight: 500;
  border-radius: 0.5rem;
  transition: background-color 0.2s;
  width: fit-content;
  align-self: flex-start;
}
.search-button:hover:not(:disabled) {
  background-color: #e35d0a;
}

/* Error Modal */
.error-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}
.error-modal__content {
  background: #fff;
  padding: 2rem;
  border-radius: 0.5rem;
}
.error-modal__button {
  background-color: #fc7115;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  margin-top: 1rem;
}

/* Main jobs section */
.jobswipe__main {
  max-width: 72rem;
  margin: 0 auto;
  padding: 1.5rem 1rem;
}
.jobs-loading {
  display: grid;
  gap: 1rem;
}
.jobs-loading__card {
  background: #fff;
  border-radius: 0.5rem;
  height: 150px;
  padding: 1rem;
  animation: pulse 1.5s infinite ease-in-out;
}
.jobs-error,
.jobs-empty {
  text-align: center;
  font-size: 1.25rem;
  color: #333;
}

/* Matched / Other Job Sections */
.matched-jobs-section {
  margin-bottom: 2rem;
}

/* Jobs Grid */
.jobs-grid {
  display: grid;
  gap: 1.5rem;
  align-items: stretch;
}
@media (min-width: 768px) {
  .jobs-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 1024px) {
  .jobs-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* Job Card */
.job-card {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 0.5rem;
  padding: 1.5rem;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  transition: box-shadow 0.2s;
}
.job-card:hover {
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1);
}
.job-card__header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.job-card__title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #fc7115;
}
.job-card__title:hover {
  text-decoration: underline;
}
.job-card__company {
  font-weight: 500;
  color: #333;
  margin-top: 0.25rem;
}
.job-card__actions {
  flex-shrink: 0;
}

.job-card__details {
  color: #333;
  margin-bottom: 1rem;
}
.job-card__details > p {
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
}

.job-card__description {
  margin-bottom: 1rem;
  color: #555;
}
.job-card__skills-title {
  font-weight: 500;
  color: #333;
  margin-bottom: 0.5rem;
}
.job-card__skills-list {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
}
.job-card__skill-tag {
  background-color: rgb(243, 244, 246);
  color: rgb(55, 65, 81);
  padding: 0.25rem 0.75rem;
  border-radius: 9999px;
  font-size: 0.875rem;
}
.job-card__cta {
  margin-top: auto;
  background-color: #fc7115;
  color: white;
  font-weight: 500;
  padding: 0.75rem;
  border-radius: 0.5rem;
  transition: background-color 0.2s;
}
.job-card__cta:hover {
  background-color: #e35d0a;
}

/* Job Modal */
.job-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.job-modal__content {
  background-color: white;
  padding: 2rem;
  border-radius: 0.5rem;
  width: 90%;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
}
.job-modal__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.job-modal__title {
  font-size: 1.5rem;
  font-weight: 700;
  color: #fc7115;
}
.job-modal__company {
  font-weight: 500;
  color: #333;
  margin-top: 0.25rem;
}
.job-modal__close {
  background: none;
  border: none;
  cursor: pointer;
}
.job-modal__details {
  margin-top: 1.5rem;
}
.job-modal__info > p {
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
}
.job-modal__section {
  margin-top: 1.5rem;
}
.job-modal__subtitle {
  font-size: 1.25rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 0.5rem;
}
.job-modal__description {
  color: #555;
}
.job-modal__skills {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}
.job-modal__skill-tag {
  background-color: rgb(243, 244, 246);
  color: rgb(55, 65, 81);
  padding: 0.25rem 0.75rem;
  border-radius: 9999px;
  font-size: 0.875rem;
}

/* Modal Actions */
.job-modal__actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1.5rem;
}
.job-modal__actions--split {
  display: flex;
  align-items: center;
}
.job-modal__actions-left {
  margin-right: auto;
  display: flex;
  align-items: center;
  gap: 1rem;
}
.job-modal__actions-right {
  display: flex;
  align-items: center;
  gap: 1rem;
}

/* “Apply on Company Site” */
.job-modal__company-site {
  background-color: #fff;
  color: #fc7115;
  border: 1px solid #fc7115;
  transition: background-color 0.2s;
}
.job-modal__company-site:hover {
  background-color: #fc711510;
}

/* “Apply via Fylter” */
.job-modal__apply {
  background-color: #fc7115;
  color: white;
  border: none;
}
.job-modal__apply:hover {
  background-color: #e35d0a;
}

/* “Share” */
.job-modal__share {
  background-color: #3b82f6;
  color: white;
  border: none;
}
.job-modal__share:hover {
  background-color: #2563eb;
}
.job-modal__share svg {
  width: 1em;
  height: 1em;
}

.icon-class {
  width: 1em;
  height: 1em;
  vertical-align: middle;
  margin-right: 0.5rem;
}

/* Load More */
.load-more {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}
.load-more__button {
  background-color: #fc7115;
  color: #fff;
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  font-weight: 500;
  border: none;
  cursor: pointer;
}
.load-more__button:hover {
  background-color: #e35d0a;
}

/* Pulse animation for loading states */
@keyframes pulse {
  0% {
    background-color: #f0f0f0;
  }
  50% {
    background-color: #e0e0e0;
  }
  100% {
    background-color: #f0f0f0;
  }
}

/* Multi-apply footer */
.multi-apply-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fc7115;
  color: #fff;
  padding: 1rem;
  display: flex;
  justify-content: center;
  gap: 2rem;
  align-items: center;
  z-index: 999;
}

.multi-apply-footer > div {
  display: flex;
  gap: 1rem;
}

.multi-apply-footer button {
  background-color: #fff;
  color: #fc7115;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  border: none;
  font-weight: 600;
  cursor: pointer;
}

.multi-apply-footer button:hover {
  opacity: 0.9;
}

/* Make "Find Your Next Opportunity" smaller on mobile */
@media (max-width: 640px) {
  .search-title {
    font-size: 1rem;
    text-align: center;
  }
}

/* Make multi-apply checkboxes bigger */
.job-select-checkbox {
  width: 1.25rem;
  height: 1.25rem;
}
</style>
